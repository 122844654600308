import kebabcase from 'lodash.kebabcase';
import i18n from '@/2/services/language';
import getNamespace from '@/2/services/namespace';

const namespace = getNamespace();

export const createCategoriesPage = ({ config, settingsPage, lang }) => {
    function addToPage(page, item, slugpage) {
        const existing = settingsPage.pages.find((it) => it.name === page);
        if (!existing) {
            const categoryPage = {
                name: page,
                slug: kebabcase(slugpage || page),
                component: 'Inline',
                in_menu: true,
                editpane: {
                    segments: [item],
                    api: {
                        localData: 'config.current.categories',
                        endpoint: 'categories',
                    },
                    hooks: [
                        {
                            on: 'save',
                            method: 'editpaneDataToCategories',
                        },
                        {
                            on: 'get',
                            method: 'categoriesDataToEditpane',
                        },
                    ],
                },
            };

            if (['polarcompany.blixem.app'].includes(namespace)) {
                categoryPage.path = '/settings/categories';
                delete categoryPage.slug;
            }
            settingsPage.pages.push(categoryPage);
        } else {
            existing.editpane.segments.push(item);
        }
    }

    Object.entries(config.categories).forEach(([key, group]) => {
        if (group.name) {
            const options = group.options || {};
            const data = {
                type: 'rules',
                data: key,
                tab: {
                    name: group.name,
                },
                options: {
                    add: typeof options.add === 'boolean' ? options.add : true,
                    indent: false,
                    indent_levels: 2,
                    sort: true,
                    lines: group.lines || {},
                },
            };
            data.fields = group.fields || [
                {
                    id: 'name',
                    name: i18n.t('fields.name', lang),
                    type: 'input',
                    size: 'minmax(25rem, auto)',
                },
            ];

            if (group.indent_levels) {
                data.options.indent = true;
                data.options.indent_levels = group.indent_levels;
            }

            if (group.if) {
                data.if = group.if;
            }

            if (
                group.items
                && typeof group.items[0] !== 'undefined'
            ) {
                if (
                    !data.options.indent
                    && typeof group.items[0].level === 'number'
                ) {
                    data.options.indent = true;
                }
                if (
                    typeof group.items[0].color !== 'undefined'
                    && !group.fields
                ) {
                    data.fields.push({
                        id: 'color',
                        name: i18n.t('fields.color', lang),
                        type: 'color',
                        size: 'minmax(25rem, auto)',
                    });
                }
            }
            if (!data.fields.some((it) => it.type === 'filler')) {
                data.fields.push({
                    id: 'filler',
                    type: 'filler',
                    size: 'auto',
                });
            }

            if (group.page) {
                addToPage(group.page, data);
            } else {
                addToPage(i18n.tc('items.categories', 2, lang), data, 'categories');
            }
        }
    });

    return settingsPage;
};

export default createCategoriesPage;
