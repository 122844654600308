<template>
    <div
        v-if="$slots.default" v-on-clickaway="clickAway" class="dropdown"
        @mouseover="openOn === 'hover' && !isTouchDevice ? changeState(true) : {}"
        @mouseleave="openOn === 'hover' && !isTouchDevice ? changeState(false) : {}"
        :class="[{ 'dropdown--open': state === 'opened' }, `dropdown--${positioning.direction}`, `dropdown--${position}`, `dropdown--${positioning.align}`]"
    >
        <div class="dropdown__trigger" :class="handleClass">
            <slot name="trigger" v-bind="{ state, changeState }">
                <button
                    type="button" :class="[handleClass, { '-icon': !$scopedSlots.label }]"
                    @click.prevent="changeState('toggle')"
                >
                    <slot name="label" v-bind="{ state }">
                        <icon class="mdi mdi-dots-vertical" />
                    </slot>
                    <icon
                        v-if="$scopedSlots.label" class="mdi color-tertiary icon-right"
                        :class="{ 'mdi-chevron-down': state === 'closed', 'mdi-chevron-up': state === 'opened' }"
                    />
                </button>
            </slot>
        </div>

        <transition name="fade">
            <div
                v-if="state === 'opened'" class="card dropdown__menu scrollbar-style" :style="menuStyle"
                @click.self="changeState(false)"
            >
                <div v-if="search" class="dropdown__search bg-hover" @click.stop>
                    <icon class="mdi mdi-magnify color-secondary size-6" />
                    <input
                        ref="search" v-model.trim="searchval" type="search" class="-s -ghost"
                        @input="$emit('search', searchval)" @click.stop
                    >
                </div>
                <slot />
            </div>
        </transition>
    </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchpoints > 0;

export default {
    name: 'Dropdown',
    directives: {
        onClickaway,
    },
    props: {
        direction: {
            type: String,
            default: 'bottom',
        },
        position: {
            type: String,
            default: 'relative',
        },
        align: {
            type: String,
            default: 'auto',
        },
        search: {
            type: Boolean,
            default: false,
        },
        handleClass: String,
        triggerClass: String,
        parentSelector: String,
        openOn: {
            type: String,
            default: 'click',
        },
    },
    data() {
        return {
            state: 'closed',
            searchval: '',
            menuStyle: {},
            positioning: {
                direction: undefined,
                align: undefined,
            },
            isTouchDevice,
        };
    },
    methods: {
        changeState(to) {
            if (typeof to !== 'boolean') { // toggle
                this.state = this.state === 'closed' ? 'opened' : 'closed';
            } else {
                this.state = (to === true) ? 'opened' : 'closed';
            }

            if (this.state === 'opened') {
                this.updatePosition();
                setTimeout(() => {
                    this.focusSearch();
                }, 100);
            }
        },

        clickAway() {
            if (this.state === 'opened') {
                this.changeState(false);
            }
        },

        clearSearch() {
            if (this.search) {
                this.searchval = '';
                this.$emit('search', '');
            }
        },

        focusSearch() {
            if (this.search) {
                this.$refs.search.focus();
            }
        },

        updatePosition() {
            const triggerPos = this.$el.getBoundingClientRect();
            const { right, bottom } = triggerPos;
            let { left, top } = triggerPos;
            let innerWidth = window.innerWidth;
            if (this.parentSelector && this.$el.closest(this.parentSelector)) {
                const parentBounds = this.$el.closest(this.parentSelector).getBoundingClientRect();
                left -= parentBounds.left;
                top -= parentBounds.top;
                innerWidth = parentBounds.width;
            }
            if (this.position === 'fixed') {
                const style = {
                    top: 'auto', right: 'auto', bottom: 'auto', left: 'auto',
                };
                if (this.direction === 'top') {
                    style.bottom = `${window.innerHeight - top}px`;
                } else {
                    style.top = `${bottom}px`;
                }
                if (this.align === 'right') {
                    style.right = `${innerWidth - right}px`;
                } else {
                    style.left = `${left}px`;
                }
                this.menuStyle = style;
            } else {
                this.menuStyle = {};
                if (this.direction === 'auto') {
                    this.positioning.direction = (window.innerHeight - bottom < 100) ? 'top' : 'bottom';
                } else {
                    this.positioning.direction = this.direction;
                }
                if (this.align === 'auto') {
                    this.positioning.align = left < (0.2 * innerWidth) ? 'left' : 'right';
                } else {
                    this.positioning.align = this.align;
                }
            }
        },
    },
};
</script>

<style>
.blixem1 .dropdown {
    display: inline-block;
    position: relative;
    text-align: left;
}

.blixem1 .dropdown__menu {
    display: block;
    min-width: 12rem;
    position: absolute;
    z-index: 20;
    list-style: none;
    overflow: hidden;
    max-height: 25rem;
    overflow: auto;
}

.blixem1 .dropdown--left .dropdown__menu {
    left: 0;
    right: auto;
}

.blixem1 .dropdown--right .dropdown__menu {
    left: auto;
    right: 0;
}

.blixem1 .dropdown--top .dropdown__menu {
    top: auto;
    bottom: 100%;
}

.blixem1 .dropdown--bottom .dropdown__menu {
    top: 100%;
    bottom: auto;
}

.blixem1 .dropdown--fixed .dropdown__menu {
    position: fixed;
}

/* #region Content */
.blixem1 .dropdown__menu>* {
    color: var(--color-primary);
    justify-content: space-between;
    align-items: center;
    font-size: var(--size-6);
    padding: 0;
    line-height: 2.4;
    height: unset;
    position: relative;
    border: unset;
    border-radius: unset;
    width: 100%;
    margin: 0;
    white-space: nowrap;
}

.blixem1 .dropdown__menu>*:not([class*='pl-']) {
    padding-left: var(--size-m);
}

.blixem1 .dropdown__menu>*:not([class*='pr-']) {
    padding-right: var(--size-m);
}

.blixem1 .dropdown__menu>*:not(.dropdown__header):not(.dropdown__search):not(.dropdown__footer) {
    display: flex;
}

.blixem1 .dropdown__menu>*:first-child:not(.dropdown__header):not(.dropdown__search) {
    margin-top: var(--size-s);
}

.blixem1 .dropdown__menu>*:last-child:not(.dropdown__footer):not(.dropdown__header) {
    margin-bottom: var(--size-s);
}

.blixem1 .dropdown__menu>*:not(.-icon)>icon {
    margin-left: var(--size-l);
    margin-right: 0;
}

.blixem1 .dropdown__menu .button.-icon,
.blixem1 .dropdown__menu button.-icon {
    width: 33%;
    display: inline-block;
}

.blixem1 .dropdown__menu .button.-icon>icon,
.blixem1 .dropdown__menu button.-icon>icon {
    margin-left: auto;
    margin-right: auto;
}

.blixem1 .dropdown__menu>a:hover,
.blixem1 .dropdown__menu>button:hover {
    background-color: var(--color-hover) !important;
    color: var(--color-hover-primary) !important;
}

.blixem1 .dropdown__menu>.-active {
    background-color: var(--color-accent);
    color: var(--color-accent-primary);
}

.blixem1 .dropdown hr {
    background-color: var(--color-border);
    border: none;
    display: block;
    height: 0.1rem;
    margin: var(--size-s) 0;
    padding: 0;
}

.blixem1 .dropdown__header+hr {
    margin-top: 0;
}

.blixem1 hr+.dropdown__footer {
    margin-top: calc(var(--size-s) * -1);
}

/* #endregion */
/* #region Search */
.blixem1 .dropdown__search {
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 0;
}

.blixem1 .dropdown__search icon {
    position: absolute;
    left: 0;
    top: var(--size);
}

.blixem1 .dropdown__search input {
    padding-left: var(--size-xxl);
}

/* #endregion */
</style>
