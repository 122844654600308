import { getStaggeredDiscount, quotesStaggeredRecalc, quotesStaggeredCalcOnProductChange } from '@/mixins/staggeredDiscounts';
import onRepairComplete from '@/mixins/repairs/repairMixin';

import { setChoiceItems, editChoiceItems } from '@/components/checklist/mixins/choiceitems';
import { setChecklistItems } from '@/components/checklist/mixins/data';

const bookingsReloadToastDelay = 5 * 60 * 1000;

function quotesGetDays({ data }) {
    const itemData = data.mainItem || {};
    const shootdates = itemData?.shootdates?.length || 0;
    const traveldays = itemData?.traveldates?.length || 0;
    return Math.max(1, (shootdates + (traveldays * 0.5)));
}

const custom_client = {
    default: {
        methods: {
            onRepairComplete,
            editChoiceItems,
        },
    },

    Editpane: {
        methods: {
            confirmSaveOnIncompleteCheckin(data) {
                return new Promise((resolve, reject) => {
                    if (
                        data.item.status === 14
                    ) {
                        this.$modal({
                            id: 'confirm',
                            path: 'Confirm',
                            title: 'Incompleet retour',
                            data: {
                                text: 'Weet je zeker dat deze boeking incompleet retour is? Heb je <strong>alle</strong> tassen en koffers dubbel gecheckt en in <strong>alle</strong> zijvakjes, pouches, hoekjes, etc. gekeken?',
                                buttonText1: this.$t('actions.yes'),
                                buttonText2: this.$t('actions.no'),
                                buttonClass2: 'bg-accent',
                                button1Resolve: true,
                                buttonShortcuts1: ['y', 'enter'],
                                buttonShortcuts2: ['n', 'esc'],
                            },
                        })
                            .then(() => {
                                resolve(data);
                            })
                            .catch(reject);
                    } else {
                        resolve(data);
                    }
                });
            },
        },
    },

    Fields: {
        methods: {
            quotesGetDays({ data }) {
                return quotesGetDays({ data });
            },

            getStaggeredDiscount,

            quotesStaggeredRecalc,

            quotesStaggeredCalcOnProductChange,

            setChecklistItems,

            setChoiceItems,

            onUpdateBookingsLinesDays({ data }) {
                const days = quotesGetDays({ data: { mainItem: data.item } });
                const discount = this.getStaggeredDiscount(days);
                data.item.bookings_lines.forEach((group) => {
                    group.items.forEach((item) => {
                        if (item.days !== days) {
                            this.$set(item, 'days', days);
                            if (!item.volume_discount_locked) {
                                this.$set(item, 'volume_discount', discount);
                            }
                        }
                    });
                });
            },
        },
    },

    Rules: {
        mounted() {
            this.$on('changed', this.onRuleChanged);
        },

        beforeDestroy() {
            this.$off('changed', this.onRuleChanged);
        },

        methods: {
            quotesGetDays({ data }) {
                return quotesGetDays({ data });
            },

            onRuleChanged(payload = {}) {
                if (this.config.api.table === 'bookings') {
                    if (payload.action === 'add-line' && !payload.item.days) {
                        // option and field are also available
                        this.$set(
                            payload.item,
                            'days',
                            this.quotesGetDays({ data: { mainItem: this.item } }),
                        );
                        if (payload.item.days && !payload.item.volume_discount_locked) {
                            this.$set(
                                payload.item,
                                'volume_discount',
                                this.getStaggeredDiscount(payload.item.days),
                            );
                        }
                    }
                }
            },

            getStaggeredDiscount,

            setChecklistItems,
        },
    },

    Items: {
        data() {
            return {
                bookingsReloadToastTimeout: null,
            };
        },
        mounted() {
            if (this.$route.name.includes('bookings')) {
                this.bookingsReloadToastTimeout = setTimeout(() => {
                    this.cinesup_reloadToast();
                }, bookingsReloadToastDelay);
            }
        },

        methods: {
            cinesup_reloadToast() {
                clearTimeout(this.bookingsReloadToastTimeout);
                if (!this.$route.meta.pane && this.$route.name.includes('bookings')) {
                    this.$toast('Data herladen?', {
                        classes: 'bg-info-theme',
                        actions: [
                            {
                                name: 'Herladen', action: 'refresh',
                            },
                            {
                                name: 'Sluiten', action: 'close', class: 'reloadtoastbutton_close',
                            },
                        ],
                        close: 'click',
                    }).then((r) => {
                        if (r.action === 'refresh') {
                            this.getItems({ trigger: 'reload' });
                        }
                    }).catch(() => { })
                        .finally(() => {
                            this.bookingsReloadToastTimeout = setTimeout(() => {
                                this.cinesup_reloadToast();
                            }, bookingsReloadToastDelay);
                        });
                } else {
                    this.bookingsReloadToastTimeout = setTimeout(() => {
                        this.cinesup_reloadToast();
                    }, bookingsReloadToastDelay);
                }
            },

            cinesup_cancelReloadToast() {
                clearTimeout(this.bookingsReloadToastTimeout);
                const button = document.querySelector('.reloadtoastbutton_close');
                if (button) { button.click(); }
            },
        },

        watch: {
            $route: 'cinesup_cancelReloadToast',
        },

        beforeDestroy() {
            this.cinesup_cancelReloadToast();
        },
    },
};

export default custom_client;
