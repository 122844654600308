import type { RouteConfig } from 'vue-router';
import page_web from '@/2/pages/web.json';
import page_404 from '@/2/pages/404.json';

const routes: RouteConfig[] = [
    {
        path: '/login',
        name: 'login',
        alias: ['/logout', '/loggedout'],
        component: () => import('@/views/Login.vue'),
        meta: {
            guest: true,
            nav: false,
            name: 'Login',
            name_only: true,
            dont_update_head: true,
        },
    },
    {
        path: '/document/:type/:slug/:view?',
        name: 'document',
        component: () => import(/* webpackChunkName: "document" */ '@/views/Document.vue'),
        props: true,
        meta: {
            guest: true,
            nav: false,
        },
    },
    {
        path: '/payments/:type/:slug/:view?',
        name: 'payments',
        component: () => import(/* webpackChunkName: "payments" */ '@/views/Payments.vue'),
        props: true,
        meta: {
            guest: true,
            nav: false,
        },
    },
    {
        path: '/web',
        name: 'web',
        component: () => import('@/views/ViewSegments.vue'),
        meta: page_web,
    },
    {
        path: '/404/:type?',
        name: '404',
        component: () => import('@/views/ViewSegments.vue'),
        meta: page_404,
        props: true,
    },
    {
        name: 'styleguide',
        path: '/styleguide',
        component: () => import('@/2/Styleguide.vue'),
        meta: {
            body_class: 'blixem2 bg-accentheader',
            guest: true,
        },
    },
    {
        name: 'cache',
        path: '/cache',
        component: () => import('@/2/Cache.vue'),
        meta: {
            body_class: 'blixem2 bg-accentheader',
            guest: true,
        },
    },
];

export default routes;
