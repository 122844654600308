import Modals from './Modals.vue';
import events from './events';

const ModalsPlugin = {
    install(Vue) {
        Vue.component('Modals', Modals);

        const addClose = (data) => new Promise((resolve, reject) => {
            events.$emit('addModal', {
                ...data,
                resolve,
                reject,
            });
        });

        const getCount = (value) => {
            if (typeof value !== 'undefined') {
                Vue.modalCount = value;
                Vue.prototype.$modalCount = value;
            }
            return Vue.modalCount;
        };

        const closeLastModal = () => {
            events.$emit('closeLastModal');
        };

        Vue.modal = addClose;
        Vue.prototype.$modal = addClose;
        Vue.hasModals = getCount;
        Vue.prototype.$hasModals = getCount;
        Vue.closeLastModal = closeLastModal;
        Vue.prototype.$closeLastModal = closeLastModal;

        events.$on('modalCount', getCount);
        getCount(0);
    },
};

export default ModalsPlugin;
