import { createCategoriesPage } from '@/mixins/categoryConvert';
import getNamespace from '@/2/services/namespace';

const namespace = getNamespace();

const createSlugs = (pages) => {
    pages.forEach((group) => {
        group.pathname = group.slug;
        if (group.pages) {
            group.pages.forEach((page) => {
                if (!page.href) {
                    page.pathname = `${group.slug}-${page.slug}`;
                    if (!page.path) {
                        page.path = `/${group.slug}/${page.slug}`;
                    }
                }
            });
        }
        if (!group.icon) {
            group.icon = 'folder';
        }
        if (group.component) {
            group.to = { name: group.pathname, query: group.to_query };
        }
    });
    pages.sort((a, b) => (a.order || 15) - (b.order || 15));
};

export const processPages = (config, lang) => {
    const pages = config.pages;
    let settingsPageIndex = -1;

    if (['polarcompany.blixem.app', 'dfpaintball.blixem.app', 'jettiepallettie.blixem.app', 'abc-entertainment.blixem.app', 'ankekranendonk.blixem.app', 'ijssalondeverleiding.blixem.app', 'geenstempel.blixem.app', 'mindfuck.blixem.app', 'dennismusic.blixem.app'].includes(namespace)) {
        pages.sort((a, b) => (a.order || 15) - (b.order || 15));
        settingsPageIndex = pages.findIndex((it) => it.id === 'settings');
    } else {
        settingsPageIndex = pages.findIndex((it) => it.slug === 'settings');
    }

    // Append pages to settings page
    if (settingsPageIndex > -1) {
        if (!pages[settingsPageIndex].pages) {
            pages[settingsPageIndex].pages = [];
        }

        if (config.categories) {
            const settingsPageWithCategories = createCategoriesPage({
                config,
                settingsPage: pages[settingsPageIndex],
                lang,
            });
            pages[settingsPageIndex] = settingsPageWithCategories;
        }
    }

    if (!['polarcompany.blixem.app', 'dfpaintball.blixem.app', 'jettiepallettie.blixem.app', 'abc-entertainment.blixem.app', 'ankekranendonk.blixem.app', 'ijssalondeverleiding.blixem.app', 'geenstempel.blixem.app', 'mindfuck.blixem.app', 'dennismusic.blixem.app'].includes(namespace)) {
        // Create slugs and sort
        createSlugs(pages);
    }

    return pages;
};

export default processPages;
