import get from 'lodash.get';
import router from '@/router';
import { transformRolesDataToStore } from '@/services/rights';

function mergeRole(pages, role) {
    const roleAndDefaultsCombined = transformRolesDataToStore(pages, role);
    const roleName = role.name.toLowerCase();
    roleAndDefaultsCombined.name = roleName;
    roleAndDefaultsCombined.id = role.id;
    return roleAndDefaultsCombined;
}

export default {
    namespaced: true,
    state: {
        roles: {},
        rolesArray: [],
        roleNames: {},
        role: '',
        roleID: undefined,
        rights: {},
    },

    mutations: {
        setRoles(state, { roles, setDefault = true }) {
            state.roles = roles;
            state.rolesArray = Object.values(roles).reduce((acc, cur) => {
                if (!acc.find((it) => it.id === cur.id)) {
                    acc.push(cur);
                }
                return acc;
            }, []);
            state.roleNames = Object.values(roles).reduce((acc, role) => {
                if (role.id && role.name) {
                    acc[role.id] = role.name;
                }
                return acc;
            }, {});

            if ((!state.role || (state.role && !state.roles[state.role])) && setDefault && state.roles.default) {
                state.rights = state.roles.default;
            }
        },

        setRole(state, { role }) {
            let newRights;
            if (Object.keys(state.roles).length && state.roles[role]) {
                newRights = state.roles[role];
            } else if (state.roles.default) {
                newRights = state.roles.default;
            }

            if (newRights) {
                state.rights = newRights;
                state.role = newRights.name;
                state.roleID = role;
            }
        },

        reset(state) {
            state.role = '';
            state.roleID = undefined;
            state.roles = {};
            state.roleNames = {};
            state.rolesArray = [];
            state.rights = {};
        },
    },

    actions: {
        updateRole({ rootState, state, commit }, { role }) {
            const id = role.id || undefined;
            const pages = rootState.config.nl.pages;
            const newRole = mergeRole(pages, role);
            let updateCurrentRole = false;

            const newRoles = JSON.parse(JSON.stringify(state.roles));
            if (id && newRoles[id]) {
                Object.entries(newRoles).forEach(([key, value]) => {
                    if (value.id === id) {
                        newRoles[key] = newRole;
                    }
                });
                // update current role
                if (state.roleID === id) {
                    updateCurrentRole = true;
                }
            } else if (id) {
                newRoles[id] = newRole;
                newRoles[role.name.toLowerCase()] = newRole;
            }
            commit('setRoles', { roles: newRoles, setDefault: false });
            if (updateCurrentRole) {
                commit('setRole', { role: id });
            }
        },

        setRoles({ rootState, commit }, { roles }) {
            const pages = rootState.config.nl.pages;
            const defaultRights = transformRolesDataToStore(pages);
            const data = roles.reduce(
                (acc, role) => {
                    const mergedRole = mergeRole(pages, role);
                    acc[mergedRole.id] = mergedRole;
                    acc[mergedRole.name] = mergedRole;

                    return acc;
                },
                { default: defaultRights },
            );

            commit('setRoles', { roles: data });
        },
    },

    getters: {
        rights: (state) => state.rights,

        roles: (state) => state.rolesArray,

        roleNames: (state) => state.roleNames,

        role: (state) => state.role,

        page: (state, _getters, _rootState, rootGetters) => ({
            page,
        }) => {
            if (page) {
                const pageList = Object.keys(state.rights).reverse(); // longest path first
                const matchPath = pageList.find((it) => page.startsWith(it));
                const pageRights = matchPath ? JSON.parse(JSON.stringify(state.rights[matchPath])) : undefined;

                if (pageRights) {
                    ['view', 'edit', 'delete'].forEach((key) => {
                        if (typeof pageRights[key] === 'undefined') {
                            if (key === 'view') {
                                pageRights[key] = true;
                            } else {
                                pageRights[key] = pageRights.view;
                            }
                        }
                    });

                    // user page
                    if ((!pageRights.view || !pageRights.edit) && page.startsWith('/settings/users/item/')) {
                        const userID = page.replace('/settings/users/item/', '');
                        if (!Number.isNaN(+userID)) {
                            const user = rootGetters['Auth/user'];
                            if (user && user.id === +userID) {
                                pageRights.view = true;
                                pageRights.edit = true;
                            }
                        }
                    }

                    return pageRights;
                }
            }
            return { view: true, delete: true, edit: true };
        },

        hasRight: (state, getters) => (right, page = router.currentRoute.path) => {
            if (typeof right === 'string') {
                right = [right];
            }
            if (!right) { return false; }
            return right.reduce((allValid, singleRight) => {
                if (!allValid) { return allValid; }

                // Check global right
                if (singleRight.indexOf('.') > -1) {
                    return get(state.rights, singleRight, true);
                }
                // Check right relative to current page
                const rights = getters.page({ page });
                const pageRight = rights[right];
                return (typeof pageRight === 'boolean') ? pageRight : true;
            }, true);
        },
    },
};

/*
Documentation:

Commands:

*  match on role name: (string)
   this.$store.getters['Rights/role'] === 'admin'
*  get page rights: (object)
   this.$store.getters['Rights/page']('/settings/users/item/1')
*  get specific right: (boolean)
   this.$store.getters['Rights/hasRight']('/settings/users.view') // use exact path of right
   this.$store.getters['Rights/hasRight']('view') // no exact path, so checks for current page
   this.$store.getters['Rights/hasRight'](['path.to.right', 'path.to.other.right']);
*/
