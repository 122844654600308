import i18n from '@/2/services/language';

export default {
    namespaced: true,
    state: {
        document: {
            always: (data = {}) => [
                {
                    id: 'download',
                    icon: 'file-download',
                    click: {
                        method: 'onLink',
                        payload: {
                            download: true,
                            url: data.attachments[0],
                        },
                    },
                    if: '!this.$route.query.timestamp || this.$route.query.timestamp === \'0\'',
                    attr: {
                        title: i18n.t('actions.download_type', { type: 'pdf' }),
                    },
                    order: 99,
                },
            ],
            signedin: (data = {}) => {
                const sendButton = {
                    id: 'send',
                    name: i18n.t('actions.send'),
                    icon: 'open-in-new',
                    rights: 'edit',
                    click: {
                        method: 'onModal',
                        payload: {
                            id: 'send',
                            path: 'Send',
                        },
                    },
                    if: '!this.$route.query.timestamp || this.$route.query.timestamp === \'0\'',
                    attr: {
                        class: 'bg-accent',
                        title: i18n.t('actions.send'),
                        id: 'send',
                    },
                    actions: [],
                };

                const backButton = {
                    id: 'back',
                    if: 'this.$route.query.returnUrl',
                    icon: 'arrow-left',
                    click: {
                        method: 'onLink',
                        payload: {
                            url_template: 'this.$route.query.returnUrl',
                            router: true,
                        },
                    },
                    attr: {
                        title: i18n.t('actions.back'),
                    },
                    order: 100,
                };

                const sendAction = JSON.parse(JSON.stringify(sendButton));
                sendAction.click.payload.data = { ...data, content: 'default' };

                if (data.settings && data.settings.send) {
                    Object.entries(data.settings.send).forEach(([key, value]) => {
                        if (!['default', 'to_filter', 'contacts'].includes(key) && value.action) {
                            let extraSendAction = JSON.parse(JSON.stringify(sendButton));
                            extraSendAction = { ...extraSendAction, ...value.action };
                            extraSendAction.click.payload.data = { ...data, content: key };
                            sendAction.actions.push(extraSendAction);
                        }
                    });
                }

                return [sendAction, backButton];
            },
        },
        editpane: {
            always: () => [
                {
                    id: 'close',
                    icon: 'close',
                    click: 'onPaneClose',
                    attr: {
                        title: i18n.t('actions.close'),
                        class: 'hover:color-danger',
                    },
                    order: 99,
                    hidden: ['inline'],
                    rights: 'close',
                },
                {
                    id: 'copy',
                    icon: 'content-copy',
                    click: 'onCopy',
                    attr: {
                        title: i18n.t('actions.copy'),
                    },
                    hidden: ['add', 'copy', 'inline', 'credit', 'variant'],
                    rights: ['copy', 'edit'],
                    order: 98,
                },
                {
                    id: 'save',
                    name: i18n.t('actions.save'),
                    icon: 'check',
                    attr: {
                        title: i18n.t('actions.save_title'),
                        class: '-dot',
                    },
                    click: 'onSave',
                    if: '!this.$route.query.timestamp || this.$route.query.timestamp === \'0\'',
                    order: 1,
                    rights: ['receive', 'edit'],
                    actions: [
                        {
                            id: 'save_template',
                            name: i18n.t('actions.save_as_template'),
                            icon: 'toy-brick-plus-outline',
                            attr: {
                                title: i18n.t('actions.save_as_template'),
                            },
                            click: {
                                method: 'onSave',
                                payload: {
                                    template: 1,
                                },
                            },
                            if: 'this.config?.templates && (!this.$route.query.timestamp || this.$route.query.timestamp === \'0\')',
                            order: 1,
                            rights: 'edit',
                        },
                    ],
                },
                {
                    id: 'saveRestore',
                    name: i18n.t('actions.restore_long'),
                    icon: 'backup-restore',
                    attr: {
                        title: i18n.t('actions.restore_long'),
                        class: 'bg-warning -dot',
                    },
                    click: {
                        method: 'onSave',
                        payload: {
                            urlParams: 'action=restore',
                        },
                    },
                    if: 'this.$route.query.timestamp && this.$route.query.timestamp !== \'0\'',
                    order: 1,
                    rights: 'edit',
                },
                {
                    id: 'delete',
                    icon: 'trash-can-outline',
                    click: 'onRemove',
                    attr: {
                        title: i18n.t('actions.delete'),
                        class: 'hover:color-danger',
                    },
                    hidden: ['add', 'copy', 'inline', 'credit', 'variant'],
                    if: '!this.$route.query.timestamp || this.$route.query.timestamp === \'0\'',
                    order: 97,
                    rights: 'delete',
                },
                {
                    id: 'delete-template',
                    icon: 'toy-brick-remove-outline',
                    click: {
                        method: 'onRemove',
                        payload: {
                            template: true,
                        },
                    },
                    attr: {
                        title: i18n.t('actions.delete_template'),
                        class: 'hover:color-danger',
                    },
                    if: 'this.$route.params.template',
                    order: 97,
                    rights: 'delete',
                },
            ],
        },
        shortcuts: {
            close: () => [
                {
                    id: 'close',
                    attr: {
                        title: i18n.t('actions.close'),
                    },
                    icon: 'close',
                    click: 'onClose',
                    order: 99,
                },
            ],

            lockable: () => [
                {
                    id: 'lockable',
                    attr: {
                        title: i18n.t('actions.lock'),
                        class: '-lockbutton',
                    },
                    icon_template:
                        "this.data.field.attr.locked ? 'lock-outline' : 'lock-open-outline'",
                    if:
                        "!this.data.field.lockable_rights || this.$store.getters['Rights/hasRight'](this.data.field.lockable_rights)",
                    click: 'onLock',
                    order: 99,
                },
            ],

            approveDocument: () => [
                {
                    id: 'approve',
                    icon: 'check',
                    name: i18n.t('actions.approve'),
                    attr: {
                        title: i18n.t('actions.approve'),
                        class: 'bg-success',
                        id: 'approve-button',
                    },
                    if: '!this.$store.getters["Auth/isAuthenticated"] && !this.$store.getters.cats({ group: \'bookings_statuses\', search: { val: this.data.item.status, query: [\'>= confirmed\'] }})',
                    click: {
                        method: 'onModal',
                        payload: {
                            id: 'ApproveDocument',
                            path: 'ApproveDocument',
                        },
                    },
                },
                {
                    id: 'approved',
                    icon: 'check-all',
                    name: i18n.t('states.approved.title'),
                    attr: {
                        title: i18n.t('states.approved.title'),
                        class: 'bg-success',
                        id: 'approve-button',
                    },
                    if: '!this.$store.getters["Auth/isAuthenticated"] && this.$store.getters.cats({ group: \'bookings_statuses\', search: { val: this.data.item.status, query: [\'>= confirmed\'] }})',
                    click: {
                        method: 'onModal',
                        payload: {
                            id: 'ApproveDocument',
                            path: 'ApproveDocument',
                            data: {
                                approved: true,
                            },
                        },
                    },
                },
            ],

            openItemId: () => [
                {
                    id: 'openproduct',
                    icon: 'open-in-new',
                    attr: {
                        title: i18n.tc('items.items', 1),
                    },
                    if: 'this.data.item.itemid',
                    click: {
                        method: 'onLink',
                        payload: {
                            url_template: "'/items/item/' + this.data.item.itemid",
                            router: true,
                        },
                    },
                },
            ],
        },
        formmodal: {
            always: () => [
                {
                    id: 'save',
                    icon: 'check',
                    name: i18n.t('actions.save'),
                    attr: {
                        class: 'bg-accent',
                        type: 'submit',
                        form: 'modal_form',
                        title: i18n.t('actions.save'),
                    },
                },
            ],
        },
    },
    getters: {
        get: (state, getters, rootState, rootGetters) => ({
            type,
            behaviour = 'extend',
            custom = [],
            data,
        }) => {
            function extendActions(base, extra) {
                const list = [...base];
                const keys = list.map((it) => it.id || '');

                extra.forEach((it) => {
                    const index = keys.findIndex((i) => i === it.id);
                    if (index > -1) {
                        if (it.merge) {
                            list[index] = Object.assign(list[index], it);
                        } else {
                            list[index] = it;
                        }
                    } else {
                        list.push(it);
                    }
                });
                return list;
            }

            const userMode = rootGetters['Auth/isAuthenticated'] ? 'signedin' : 'guest';
            let actions = [];
            switch (behaviour) {
                case 'replace':
                    actions = custom;
                    break;

                default:
                    // extend
                    actions = typeof state[type].always === 'function' ? state[type].always(data) : [];
                    if (typeof state[type][userMode] === 'function') {
                        actions = extendActions(state[type][userMode](data), actions);
                    }

                    if (custom.length) {
                        actions = extendActions(actions, custom);
                    }
                    break;
            }
            return actions;
        },
    },
};
