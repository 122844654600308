import { api } from '@/assets/js/api';

export function completeRepair({
    fixed_damage_ids,
    table_damages,
    damage_archived_status,
    damages_status_key,
    repair_id,
    table_repairs,
    repair_archived_status,
    repair_dead_status,
    repairs_status_key,
    repairs_enddate_key,
    serial_id,
    table_serials,
    serials_amount_key,
    status,
}) {
    return new Promise((resolve, reject) => {
        const promises = [];

        // Set damages to archived
        if (Array.isArray(fixed_damage_ids) && fixed_damage_ids.length) {
            const damagesData = fixed_damage_ids.map((id) => ({
                id,
                [damages_status_key]: damage_archived_status,
            }));

            promises.push(
                api.patch({
                    name: 'archiveDamages',
                    endpoint: `items/${table_damages}/`,
                    data: damagesData,
                    loading: true,
                }),
            );
        }

        // Set repair-end date and archive status.
        if (repair_id) {
            promises.push(
                api.patch({
                    name: 'archiveRepair',
                    endpoint: `items/${table_repairs}/${repair_id}/`,
                    loading: true,
                    data: {
                        [repairs_status_key]: status === 'fixed' ? repair_archived_status : repair_dead_status,
                        [repairs_enddate_key]: Math.floor(Date.now() / 1000),
                    },
                }),
            );
        }

        // Set serial-amount to 0 if repair failed
        if (serial_id && status === 'dead') {
            promises.push(
                api.patch({
                    name: 'reduceSerialAmount',
                    endpoint: `items/${table_serials}/${serial_id}/`,
                    loading: true,
                    data: {
                        [serials_amount_key]: 0,
                    },
                }),
            );
        }

        Promise.all(promises)
            .then(resolve)
            .catch((r) => {
                console.error(r);
                reject(r);
            });
    });
}

export default completeRepair;
