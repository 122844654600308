export default {
    data() {
        return {
            refreshing: false,
            registration: null,
        };
    },
    created() {
        document.addEventListener('swUpdated', this.updateAvailable, { once: true });

        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (this.refreshing) return;
                this.refreshing = true;
                window.location.reload();
            });
        }
    },
    methods: {
        updateAvailable(event = {}) {
            this.registration = event.detail;

            this.$toast('A new version is available', {
                classes: 'bg-info-theme',
                actions: [
                    {
                        name: 'Update', action: 'refresh',
                    },
                ],
                close: 'click',
            }).then((r) => {
                if (r.action === 'refresh') {
                    this.refreshApp();
                }
            }).catch(() => {});
        },

        refreshApp() {
            if (!this.registration || !this.registration.waiting) return;
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        },
    },
};
