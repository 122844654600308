import { generateID } from '@/services/helpers-ts';

// Documentation
/*
rights: false, // no rights
rights: {
    key: boolean, // key must exist as actions[key] in app-translation files.
    key: {
        value: boolean,
        hidden: boolean,
    }
}
*/

const DEFAULTS = {
    pageSlug: 'roles',
    segmentData: 'rights',
    segmentType: 'rules',
};

function getDefaultRights(page) {
    if (page.component === 'Inline') {
        return {
            view: true,
            edit: true,
            delete: {
                value: false,
                hidden: true,
            },
        };
    }
    if (page.component === 'Cards') {
        return {
            view: {
                value: true,
                hidden: true,
            },
        };
    }
    if (!page.component) {
        if (page.pages) {
            return {
                view: true,
                edit: {
                    value: false,
                    hidden: true,
                },
                delete: {
                    value: false,
                    hidden: true,
                },
            };
        }
        return false;
    }
    return {
        view: true,
        edit: true,
        delete: true,
    };
}

function getRightsFromPage(page, parentName) {
    // page.rights can contain `hide` array with un-editable rights.
    if (typeof page.rights === 'boolean' && page.rights === false) {
        return undefined;
    }
    const rights = (page.rights || getDefaultRights(page));
    if (rights === false) { return undefined; }

    // TODO remove pathnames here
    let pageName = page.path || page.pathname;
    if (!pageName) { return undefined; }

    if (!pageName.startsWith('/')) {
        pageName = `/${pageName}`;
    }

    return {
        name: `${parentName ? `${parentName} - ` : ''}${page.name}`,
        page: pageName,
        ...rights,
    };
}

function getRightsFromConfigPages(pages, name) {
    return pages.reduce((acc, page) => {
        const result = getRightsFromPage(page, name);
        if (result) {
            acc.push(result);
        }
        if (page.pages) {
            const subpagesResult = getRightsFromConfigPages(page.pages, page.name);
            if (subpagesResult.length) {
                acc.push(...subpagesResult);
            }
        }
        return acc;
    }, []);
}

function getUniqueRights(rights) {
    return rights.reduce((acc, page) => {
        Object.entries(page).forEach(([key, value]) => {
            if (
                typeof value === 'boolean'
                || (typeof value === 'object'
                    && typeof value.value === 'boolean'
                    && value.hidden !== true)
            ) {
                if (!acc[key]) {
                    acc[key] = {
                        pages: [page.page],
                    };
                } else {
                    acc[key].pages.push(page.page);
                }
            }
        });
        return acc;
    }, {});
}

function createFieldsFromRights(rights) {
    const uniqueRights = getUniqueRights(rights);
    return Object.entries(uniqueRights).reduce((acc, [right, value]) => {
        const field = {
            id: right,
            name_template: `this.$i18n.t('actions.${right}')`,
            type: 'checkbox',
            size: '10rem',
            applyValueToEntireColumn: false,
        };
        if (rights.length !== value.pages.length) {
            field.disabled_template = `!${JSON.stringify(
                value.pages,
            )}.includes(this.data.item.page)`;
        }
        if (right !== 'view') {
            const disabled_template = 'this.data.item.view !== true';
            if (field.disabled_template) {
                field.disabled_template += ` || ${disabled_template}`;
            } else {
                field.disabled_template = disabled_template;
            }
        }
        acc.push(field);
        return acc;
    }, []);
}

function findPageBySlug(pages, slug) {
    return pages.reduce(
        (acc, cur) => acc
            || (cur.slug === slug ? cur : cur.pages && cur.pages.find((it) => it.slug === slug))
            || undefined,
        undefined,
    );
}

function setRolesFields(page, fields) {
    const segments = page.items?.editpane?.segments;
    if (segments) {
        const segment = segments.find(
            (it) => it.type === DEFAULTS.segmentType && it.data === DEFAULTS.segmentData,
        );
        segment.fields.splice(1, 0, ...fields);
    }
}

function flattenRightObjects(line, { addIDs = false }) {
    if (addIDs) {
        line.id = generateID();
    }
    Object.entries(line).forEach(([key, val]) => {
        if (typeof val === 'object' && typeof val.value === 'boolean') {
            line[key] = val.value;
        }
    });
    return line;
}

function mergeRoleDataIntoLines(roleRights, lines) {
    roleRights.forEach((line) => {
        const match = lines.find((it) => it.page === line.page);
        if (match) {
            match.id = line.id;

            // line.rights is a string when saved locally,
            // an object when saved with the api
            if (line.rights) {
                if (typeof line.rights === 'string') {
                    line.rights = JSON.parse(line.rights);
                }
                const matchKeys = Object.keys(match);
                const mayViewLine = typeof line.rights.view === 'boolean' ? line.rights.view : match.view;
                Object.entries(line.rights).forEach(([key, value]) => {
                    if (matchKeys.includes(key) && typeof value === 'boolean') {
                        if (key === 'view' || mayViewLine) {
                            match[key] = value;
                        } else {
                            match[key] = false;
                        }
                    }
                });
            }
        }
    });
}

export function getRightsSegment(config) {
    Object.entries(config).reduce((acc, [, langConfig]) => {
        if (langConfig.pages) {
            const rights = getRightsFromConfigPages(langConfig.pages);
            const fields = createFieldsFromRights(rights);
            const rolesPageConfig = findPageBySlug(langConfig.pages, DEFAULTS.pageSlug);
            if (!rolesPageConfig) {
                console.debug('No roles page config');
            } else {
                setRolesFields(rolesPageConfig, fields);
            }
        }
        return acc;
    }, {});
}

export function transformRolesDataToStore(pages, data) {
    const lines = getRightsFromConfigPages(pages);

    // Add ids to lines
    lines.map((line) => flattenRightObjects(line, { addIDs: false }));

    // Merge data into fresh lines object
    if (data) {
        mergeRoleDataIntoLines(data.rights[0].items, lines);
    }

    return lines.reduce((acc, cur) => {
        acc[cur.page] = cur;
        return acc;
    }, {});
}

export function transformRolesDataToEditpane(pages, data) {
    const lines = getRightsFromConfigPages(pages);

    return new Promise((resolve) => {
        // Add ids to lines
        lines.map((line) => flattenRightObjects(line, { addIDs: true }));

        if (!data.rights?.[0]?.items) {
            data.rights = [
                {
                    id: generateID(),
                    name: '',
                    items: [],
                },
            ];
        }

        // Merge data into fresh lines object
        mergeRoleDataIntoLines(data.rights[0].items, lines);

        data.rights[0].items = lines;
        resolve(data);
    });
}

export function transformEditpaneDataToRoles(data) {
    return new Promise((resolve) => {
        const lines = data.item.rights[0].items;
        const keysToSkip = ['name', 'page', 'id', 'rights'];
        data.item.rights[0].items = lines.map((line) => {
            const rights = {};
            Object.keys(line).forEach((key) => {
                if (!keysToSkip.includes(key) && typeof line[key] === 'boolean') {
                    rights[key] = line[key];
                }
            });
            return { ...line, rights: JSON.stringify(rights) };
        });
        resolve(data);
    });
}

export default getRightsSegment;
