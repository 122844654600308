import { set, reactive } from 'vue';
import type { ModalProps, Modal } from './types';
import { createModal, focusModal, getLastOpenedModalID } from './functions';

export const modals = reactive<Record<string, Modal>>({});

export default function useModals() {
    function add<T>(settings: ModalProps) {
        const { modal, promise } = createModal<T>(settings, modals);
        if (modal) {
            // NOTE vue2 needs 'set' to make it reactive
            // this breaks passing extendedrefs to modals (ie: parent_sources)
            set(modals, modal.id, modal);
            // modals[modal.id] = modal;
            focusModal(modal.id);
        }
        return promise;
    }

    function close(id: string, type: 'resolve' | 'reject' = 'resolve', data?: Record<string, any>) {
        const modal = modals[id];
        if (typeof modal?.close === 'function') { modal.close(type, data); }
    }

    function closeLast() {
        const lastOpenedId = getLastOpenedModalID(modals);
        if (lastOpenedId) { close(lastOpenedId, 'reject'); }
    }

    return {
        add,
        close,
        closeLast,
        getCount: () => Object.keys(modals).length,
    };
}
