export function quotesGetDays({ data }) {
    const itemData = data.mainItem || {};
    let days = 1;
    if (itemData.pickup && itemData.return) {
        days = Math.floor((itemData.return - itemData.pickup) / 86000);
    }
    return days > 0 ? days : 1;
}

export function getStaggeredDiscount(days, product) {
    if (!days) {
        return 0;
    }

    let discountlist;

    const multiVolumeDiscountsLists = this.$store.getters.cats({ group: 'volume_discounts' }) || {};
    if (Object.keys(multiVolumeDiscountsLists).length) {
        const appliedDiscount = (product ?? this.data?.item?.product_info)?.volume_discount;
        if (!appliedDiscount) {
            return 0;
        }
        discountlist = multiVolumeDiscountsLists[appliedDiscount];
    } else {
        discountlist = { items: Object.values(this.$store.getters.cats({ group: 'discounts', defaultOutput: {} })) };
    }

    if (discountlist?.items) {
        const discount = discountlist.items.find((it) => it.days_from <= days && it.days_to >= days);
        if (discount) {
            return discount.discount || 0;
        }
    }
    return 0;
}

export function quotesStaggeredRecalc({
    value,
    field,
    data = {},
    trigger,
}) {
    if (field?.id === 'days' && trigger === 'rules' && data.item) {
        if (
            typeof data.item.volume_discount_locked === 'undefined'
            || !data.item.volume_discount_locked
        ) {
            let amount = 0;
            if (typeof value === 'number') {
                amount = value;
            } else if (typeof value === 'object' && value.value && typeof value.value === 'number') {
                amount = value.value;
            } else if (typeof data.item.days === 'number') {
                amount = data.item.days;
            }
            this.$set(data.item, 'volume_discount', this.getStaggeredDiscount(amount));
        }
    }
}

export function quotesStaggeredCalcOnProductChange(data) {
    data.item.volume_discount = this.getStaggeredDiscount(data.item.days, data.item.product_info);
    return data;
}