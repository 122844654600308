import { completeRepair } from './repairFunctions';

/**
 * @param {object} data
 * @param {number[]} data.fixed_damage_ids
 * @param {number} data.repair_id
 * @param {string} data.table_damages
 * @param {string} data.table_damages_statuses
 * @param {string} data.table_repairs
 * @param {string} data.table_repairs_statuses
 * @param {number} data.serial_id
 * @param {string} data.table_serials
 * @param {string} [data.status_key=status]
 * @param {string} [data.repairs_status_key=data.status_key]
 * @param {string} [data.repairs_enddate_key=to]
 * @param {string} [data.damages_status_key=data.status_key]
 * @param {string} [data.serials_amount_key=amount]
 * @param {string} status - fixed / dead
 */
export default function onRepairComplete({ data, status }) {
    const {
        fixed_damage_ids,
        repair_id,
        table_damages,
        table_damages_statuses,
        table_repairs,
        table_repairs_statuses,
        status_key = 'status',
        repairs_status_key,
        repairs_enddate_key = 'to',
        damages_status_key,
        table_serials,
        serial_id,
        serials_amount_key = 'amount',
    } = data;
    function findStatus(group, val, ctx) {
        const result = ctx.$store.getters.cats({ group, search: { val } });
        if (result && result[0]) {
            return result[0].id;
        }
        return undefined;
    }

    return completeRepair({
        fixed_damage_ids,
        repair_id,
        table_damages,
        table_repairs,
        repairs_status_key: repairs_status_key || status_key,
        repairs_enddate_key,
        damages_status_key: damages_status_key || status_key,
        damage_archived_status: findStatus(table_damages_statuses, 'archived', this),
        repair_archived_status: findStatus(table_repairs_statuses, 'archived', this),
        repair_dead_status: findStatus(table_repairs_statuses, 'dead', this),
        serial_id,
        table_serials,
        serials_amount_key,
        status,
    });
}
