import Vue from 'vue';
import Router from 'vue-router';
import routes from './default_routes';
import i18n from '@/2/services/language';
import qs from 'qs';

Vue.use(Router);

const storedScrollPositions = {};

function createRouter() {
  return new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'link__active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve) => {
            if (savedPosition) {
                if (document.documentElement.classList.contains('blixem2')) {
                    // create an Observer instance
                    const resizeObserver = new ResizeObserver(entries => {
                        if (entries[0].target.offsetHeight >= savedPosition.y) {
                            resolve(savedPosition);
                            resizeObserver.disconnect();
                        }
                    });

                    resizeObserver.observe(document.documentElement);
                } else {
                    resolve(savedPosition);
                }
            } else if (to.hash) {
                resolve({
                    el: to.hash,
                    behavior: 'smooth',
                });
            } else if (to.name && from.name && `${to.name}-item` === from.name && storedScrollPositions[to.name]) {
                const returnPos = JSON.stringify(storedScrollPositions[to.name]);
                delete storedScrollPositions[to.name];
                resolve(JSON.parse(returnPos));
            } else {
                resolve({
                    top: 0,
                    left: 0,
                });
            }
        });
    },
    // These are neccecary to make the router work with nested query params: ds[items][query]
    parseQuery(query) {
        return qs.parse(query)
    },
    stringifyQuery(query) {
        const result = qs.stringify(query, { encode: false });
        return result ? `?${result}` : '';
    }
})
}

// use scrollbehaviour on custom element;
// TODO remove this, to use scroll on body
Object.defineProperty(window, 'pageYOffset', {
    get() {
        if (document.documentElement.classList.contains('blixem2')) {
            return document.documentElement.scrollTop ?? 0;
        } else {
            return document.querySelector('main')?.scrollTop ?? 0;

        }
    },
});
Object.defineProperty(window, 'scrollTo', {
    value: (option) => {
        if (document.documentElement.classList.contains('blixem2')) {
            window.scroll(option.left, option.top);
        } else {
            const els = document.querySelectorAll('main');
            const el = els[els.length - 1];
            el?.scrollTo(option.left, option.top);
        }
    },
});

const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher;
}

function meta(to) {
    const { app } = store.state;
    let title = app.name;

    if (to.meta && to.meta.name) {
        let { name } = to.meta;
        if (typeof to.meta.name === 'function') {
            name = to.meta.name(to);
        }
        title = to.meta.name_only ? name : `${name}${app.name_separator}${title}`;
    }

    if (title.includes('%')) {
        title = title.replace(/%(\w.+)%/g, (m, m1) => {
            const parts = m1.split('.');
            let val = to.params[parts[0]];
            if (val && parts[1] === 'translate') {
                const translatepart = parts.slice(2, 4).join('.').replace('prop', val);
                val = i18n.t(translatepart);
            }
            return val || '';
        });
    }

    document.title = title;
    if (!to.meta.update_meta === false && app.update_meta) {
        let desc = app.meta.description;
        let img = app.meta.image;
        const url = app.base_url + to.fullPath;

        if (to.meta) {
            if (to.meta.description) { desc = to.meta.description; }
            if (to.meta.image) { img = to.meta.image; }
        }

        const title_items = document.head.getElementsByClassName('meta__title');
        for (let i = 0, len = title_items.length; i < len; i += 1) {
            title_items[i].setAttribute('content', title);
        }

        const url_items = document.head.getElementsByClassName('meta__url');
        for (let i = 0, len = url_items.length; i < len; i += 1) {
            url_items[i].setAttribute('content', url);
        }
        document.head.getElementsByClassName('canonical__url')[0].setAttribute('href', url);

        const desc_items = document.head.getElementsByClassName('meta__desc');
        for (let i = 0, len = desc_items.length; i < len; i += 1) {
            desc_items[i].setAttribute('content', desc);
        }

        const img_items = document.head.getElementsByClassName('meta__image');
        for (let i = 0, len = img_items.length; i < len; i += 1) {
            img_items[i].setAttribute('content', img);
        }
    }
}

router.beforeEach(async (to, from, next) => {
    let classesToAdd = to?.meta?.body_class || '';
    let classesToRemove = from?.meta?.body_class || '';
    if (classesToAdd.includes('blixem2')) {
        classesToRemove += ' blixem1';
        classesToRemove = classesToRemove.trim();
    } else {
        classesToAdd += ' blixem1';
        classesToAdd = classesToAdd.trim();
    }

    if (classesToRemove) {
        document.documentElement.classList.remove(...classesToRemove.split(' '));
    }
    if (classesToAdd) {
        document.documentElement.classList.add(...classesToAdd.split(' '));
    }

    const LoginOrGetConfig = () => new Promise((resolve, reject) => {
        if (store.getters['Auth/isAuthenticated']) {
            resolve();
        } else {
            store
                .dispatch('initSession')
                .then(() => {
                    resolve('session_fetched');
                })
                .catch(() => {
                    // still not signed in. Get config
                    store
                        .dispatch('getConfig')
                        .then(() => {
                            resolve();
                        })
                        .catch((err) => {
                            console.debug('could not fetch config', err);
                            reject();
                        });
                });
        }
    });

    if (to.name && from.name && to.name === `${from.name}-item`) {
        storedScrollPositions[from.name] = { x: document.getElementById('main').scrollLeft, y: document.getElementById('main').scrollTop };
    }
    if (to.name === 'login' || to.matched.some(page => page.meta.noConfig)) {
        next();
    } else {
        // Optimize - stop double configuration call on guest-calls
        LoginOrGetConfig()
            .then((r) => {
                if (r === 'session_fetched') {
                    next(to); // Reload page, to use updated `matched`
                } else if (!to.matched.length) {
                    if (store.getters['Auth/isAuthenticated']) {
                        next({ path: '/404', params: { error: to.path } });
                    } else {
                        // try to login
                        next({ path: '/login', query: { redirect: to.path } });
                    }
                } else if (store.getters['Auth/isAuthenticated']) {
                    // signed in, continue
                    if (store.getters['Rights/hasRight']('view', to.path)) {
                        next();
                    } else {
                        next({ path: '/404/rights', params: { error: to.path } });
                    }
                } else if (!to.matched.every(page => page.meta.guest)) {
                    // pages requires auth
                    next({ path: '/login', query: { redirect: to.path } });
                } else {
                    // guest page, continue
                    next();
                }
                meta(to);
            })
            .catch(console.error);
    }
});

router.onError((e) => {
    router.replace({ path: '/404', params: { error: e } });
});

export default router;
