import Vue from 'vue';
import i18n from '@/2/services/language';

export default {
    namespaced: true,
    state: {
        errors: {
            16: 403,
            99: {
                name: 'Unspecified',
                message: i18n.t('errors.unspecified_msg'),
                fatal: true,
                callback: () => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 5000);
                },
            },
            120: {
                name: 'Under construction',
                title: i18n.t('errors.construction'),
                message: i18n.t('errors.construction'),
                fatal: true,
                callback: () => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 120000);
                },
            },
            400: {
                name: 'Unsupported',
                fatal: false,
                title: i18n.t('errors.unsupported'),
                useResponseMsg: true,
            },
            403: {
                name: 'Invalid API key',
                fatal: false,
                useResponseMsg: true,
                callback: () => {
                    if (
                        !['/login', '/login/', '/logout', '/logout/'].includes(
                            router.currentRoute.path,
                        )
                    ) {
                        router.push({
                            path: '/logout',
                            query: { redirect: router.currentRoute.path },
                        });
                    }
                },
            },
            429: {
                name: 'Ratelimit reached',
                fatal: true,
                useResponseMsg: false,
                title: i18n.t('errors.ratelimit'),
                message: i18n.t('errors.ratelimit_msg'),
            },
            503: {
                name: 'Service not available',
                fatal: true,
                useResponseMsg: false,
                title: i18n.t('errors.unavailable'),
                message: i18n.t('errors.unavailable_msg'),
            },
            99992: {
                name: 'Timeout',
                fatal: false,
                title: i18n.t('errors.timeout'),
                message: i18n.t('errors.timeout_msg'),
            },
            99993: {
                name: 'Network error',
                fatal: false,
                title: '',
                message: i18n.t('errors.networkerror_msg'),
            },
        },
    },
    getters: {
        errorByCode: (state) => (code) => {
            let obj = state.errors[code];
            if (typeof obj === 'number') {
                obj = state.errors[obj];
            }
            return obj;
        },
    },

    actions: {
        fire({ dispatch }, { error, allowedCodes = [] }) {
            let errordata = error.data || {};
            if (errordata && typeof error.data === 'string') {
                errordata = JSON.parse(errordata);
            }

            let statuscode = error.status || 0;
            if (errordata.code) {
                statuscode = errordata.code;
            } else if (error.message === 'Timeout') {
                statuscode = 99992;
            } else if (error.message === 'Network error') {
                statuscode = 99993;
            }
            // console.log('^-- this one', { data: error.data, msg: error.message, name: error.name });

            console.error(`🔥 Api Error: ${errordata.message || ''}`, error, statuscode);
            if (!allowedCodes.includes(statuscode)) {
                dispatch('process', { code: statuscode, data: errordata });
            }
        },

        process({ getters }, { code, data }) {
            const errorObj = getters.errorByCode(code);
            if (errorObj) {
                const msg = errorObj.useResponseMsg
                    ? data.message || 'Error'
                    : errorObj.message || 'Error';
                Vue.toast({ msg, classes: 'bg-danger' });
                // if (errorObj.fatal === false) {
                // } else if (errorObj.fatal === true) {
                //     store.state.app.loading_fullscreen.show = true;
                //     store.state.app.loading_fullscreen.title = errorObj.title || 'Error';
                //     store.state.app.loading_fullscreen.message = msg;
                // }

                if (typeof errorObj.callback === 'function') {
                    errorObj.callback();
                }
            } else {
                console.warn('ErrorObj not found', code, data);
            }
        },
    },
};
