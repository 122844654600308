// Create _map-values for categories, to quickly fetch them by their id.
export const buildCategoryMaps = (categories) => {
    Object.entries(categories).forEach(([, group]) => {
        if (group.items) {
            group.items_map = group.items.reduce((acc, cur) => {
                acc[cur.id] = cur;
                return acc;
            }, {});
        }
    });

    return categories;
};

export const setCategoryMaps = ({ config, cats }) => {
    Object.values(config).forEach((lang) => {
        lang.categories = cats;
    });
    return config;
};
