import Vue from 'vue';
import { updateMetadata } from '@/services/errorhandling/';
import { processConfig } from '@/services/config/index';
import i18n from '@/2/services/language';
import socket from '@/2/services/useSockets.instance';

export default {
    namespaced: false,
    state: {
        authProviders: [],
    },

    actions: {
        login({ commit, dispatch }, { user, users, data }) {
            return new Promise((resolve, reject) => {
                const promises = [];
                if (user) {
                    promises.push(new Promise((res) => { res({ session: user[0] }); }));
                } else if (users.length) {
                    promises.push(
                        Vue.prototype.$modal({
                            id: 'accountswitcher',
                            path: 'ui/AccountSwitcher/index.vue',
                            data: {
                                accounts: users,
                            },
                        }),
                    );
                }

                Promise.all(promises)
                    .then((r) => {
                        if (users && r[0] && r[0].session) {
                            if (!user) {
                                commit('Auth/login', {
                                    token: r[0].session.token,
                                    user: r[0].session.user,
                                    users,
                                });
                            }
                            dispatch('setupApp', { data, user: r[0].session.user }).then(resolve);
                        } else {
                            throw new Error('no user');
                        }
                    })
                    .catch(reject);
            });
        },

        logout({ commit }) {
            // reset application-contents
            commit('setAppData', { settings: { cdn: '' } });
            commit('setConfig', { config: { empty: true } });
            commit('localStore', undefined);
            commit('Documents/clear');
            commit('Rights/reset');

            socket.close();
        },

        initSession({ dispatch }, options = {}) {
            return new Promise((resolve, reject) => {
                dispatch('Auth/restoreSession')
                    .then((r) => {
                        if (options.updateLogin !== false) {
                            dispatch('login', r)
                                .then(resolve)
                                .catch(reject);
                        } else {
                            // Process updated application config
                            dispatch('setupApp', { data: r.data }).then(resolve);
                        }
                    })
                    .catch(reject);
            });
        },

        switchAccounts({ dispatch, getters }) {
            const accounts = getters['Auth/accounts'];
            dispatch('login', { users: accounts, accounts }).then(() => {
                const { name, query, params } = router.currentRoute;
                router.push('/404').then(() => {
                    router.push({ name, query, params });
                });
            }).catch(() => { });
        },

        // Start scripts
        setupApp({ dispatch }, { data, user }) {
            return new Promise((resolve) => {
                if (data) {
                    const config = processConfig({ config: data.application.config });
                    dispatch('setAppData', { config, lang: (user && user.lang ? user.lang : undefined) }).then(resolve);
                }
                if (user) {
                    if (window.client_functions.login && typeof window.client_functions.login.methods.onBeforeSetUser === 'function') {
                        user = window.client_functions.login.methods.onBeforeSetUser(user);
                    }
                    dispatch('updateUser', { user, mode: 'init' });
                }
                if (!data) {
                    resolve();
                }
            });
        },

        updateUser({ commit, dispatch }, { user, mode }) {
            dispatch('setUserLang', { user, mode });
            updateMetadata({ user }); // Errorhanding
            if (mode === 'init') {
                commit('Rights/setRole', { role: user.role });
            } else {
                socket.close();
                commit('Auth/login', { user });
            }
            dispatch('startSocket', user);
        },

        setUserLang({ dispatch }, { user, mode }) {
            if (user.lang && user.lang !== i18n.locale) {
                i18n.locale = user.lang;

                if (mode !== 'init') {
                    dispatch('setAppData', { lang: user.lang });
                }
            }
        },

        startSocket({ getters }, user) {
            socket.io.opts.query = {
                namespace: getters.namespace,
                username: user.name,
                userid: user.id,
                useravatar: user.avatar,
            };
            socket.open();
        },
    },
};
