import Toasts from './Toasts.vue';
import events from './events';

const optionDefaults = {
    timeout: 4000,
};

const ToasterPlugin = {
    install(Vue, opts) {
        const options = { ...optionDefaults, ...opts };

        Vue.component('Toasts', Toasts);

        const toastFunction = (message, toastOptions = {}) => {
            const id = toastOptions.id ? toastOptions.id : Math.floor(Math.random() * 1000 + 1);
            const toastPromise = new Promise((resolve, reject) => {
                if (typeof message === 'object' && typeof message.msg === 'string') {
                    toastOptions = { ...message };
                    message = message.msg;
                } else if (typeof message === 'object' && typeof message.title === 'string') {
                    toastOptions = { ...message };
                    message = message.title;
                }
                const toast = {
                    msg: message,
                    options: { ...options, ...toastOptions },
                    id,
                    resolve,
                    reject,
                    created: Date.now(),
                };
                events.$emit('addToast', toast);
            });
            return toastPromise;
        };

        const toastRemove = (id, trigger, cancel) => {
            events.$emit('removeToast', id, trigger, cancel);
        };

        Vue.toast = toastFunction;
        Vue.prototype.$toast = toastFunction;
        Vue.prototype.$toast_remove = toastRemove;
    },
};

export default ToasterPlugin;
