// TODO refactor to vite plugin
import Vue from 'vue';
import VueI18n from 'vue-i18n';

import {
    dateTimeFormats as dateTimeFormatsList,
    getNumberFormats,
} from '@projectfive/services';

Vue.use(VueI18n);

function loadLocaleMessages() {
    const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages: Record<string, any> = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

const params = new URLSearchParams(window.location.search);
const defaultLang = params.get('lang') || process.env.VUE_APP_I18N_LOCALE || 'en';
const messages = loadLocaleMessages();
const locales = Object.keys(messages);

const datetimeFormats = locales.reduce((acc, cur) => {
    acc[cur] = dateTimeFormatsList;
    return acc;
}, {} as Record<string, typeof dateTimeFormatsList>);

const numberFormats = locales.reduce((acc, cur) => {
    acc[cur] = getNumberFormats();
    return acc;
}, {} as Record<string, ReturnType<typeof getNumberFormats>>);

export default new VueI18n({
    locale: defaultLang,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages,
    dateTimeFormats: datetimeFormats,
    numberFormats,
});
