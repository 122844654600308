import { onSaveInvoiceVariants } from '@/mixins/invoices.paymentParts';

export const purchaseOrderStates = {
    CONCEPT: 1,
    PARTIALLY_ORDERED: 2,
    ORDERED: 3,
    PARTIALLY_DELIVERED: 4,
    DELIVERED: 5,
    CANCELLED: 6,
    PARTIALLY_PROCESSED_IN_WORK: 7,
    PROCESSED_IN_WORK: 8,
    PARTIALLY_LOADED: 9,
    LOADED: 10,
}

export function determineMasterPurchaseOrderStatus(statuses) {
    // Check if length = 1
    if (statuses.size === 1) {
        // if so, set group status to that status
        return statuses.values().next().value;
    } else {
        // delete unchecked statuses from set
        statuses.delete(purchaseOrderStates.CANCELLED);

        // Check if length = 1
        if (statuses.size === 1) {
            // if so, set group status to that status
            return statuses.values().next().value;
        } else {
            // statuses can be CONCEPT, ORDERED, DELIVERED, PROCESSED_IN_WORK, LOADED
            // find the lowest status value and return the closest partially status
            // delivered's closest partially value is an exception, since we need to skip a value.
            let lowestStatus = Math.min(...statuses);
            if (lowestStatus === purchaseOrderStates.DELIVERED) {
                return purchaseOrderStates.PARTIALLY_PROCESSED_IN_WORK;
            }
            return lowestStatus + 1;
        }
    }
}

function onPurchaseOrderLineStatusUpdate(order) {
    // Loop through groups in mainItem.purchase_order_lines
    const groupStatuses = new Set();

    order.purchase_orders_lines.forEach((group) => {
        const itemStatuses = new Set();
        // add all statuses to set
        group.items.forEach((item) => {
            itemStatuses.add(item.status);
        });
        if (itemStatuses.size === 0) {
            itemStatuses.add(purchaseOrderStates.CONCEPT);
        }

        const groupStatus = determineMasterPurchaseOrderStatus(itemStatuses);
        group.status = groupStatus;
        groupStatuses.add(groupStatus);
    });

    order.status = determineMasterPurchaseOrderStatus(groupStatuses);
}

const custom_client = {
    Editpane: {
        methods: {
            deleteUnusedCalculations(data) {
                data.data.item.quotes_calculations_lines = data.data.item.quotes_calculations_lines.filter((item) => {
                    return !!item.starred;
                });
            },

            async onSaveDrawingboardPage(data) {
                // recalculate supplier-group-statuses
                const supplierGroupsBySupplierID = new Map();
                const supplierGroups = {};
                const unmatchedLines = [];

                // collect supplier groups
                data.item.drawing_board_lines.forEach((group) => {
                    group.items.forEach((item) => {
                        let groupID = item.group;
                        let supplierID = item.product_info?.supplier?.id;

                        if (groupID && supplierID && !supplierGroupsBySupplierID.has(supplierID)) {
                            supplierGroupsBySupplierID.set(supplierID, groupID);
                        }
                    });
                });

                // match statuses to groups
                data.item.drawing_board_lines.forEach((group) => {
                    const groupStatuses = new Set();
                    group.items.forEach((item) => {
                        let groupID = item.group;
                        let supplierID = item.product_info?.supplier?.id;

                        if (!groupID && supplierID && supplierGroupsBySupplierID.has(supplierID)) {
                            groupID = supplierGroupsBySupplierID.get(supplierID);
                        }

                        if (!groupID) {
                            unmatchedLines.push(item);
                            return;
                        }

                        if (!supplierGroups[groupID]) {
                            supplierGroups[groupID] = new Set();
                        }
                        if (!item.status) {
                            item.status = purchaseOrderStates.CONCEPT;
                        }
                        groupStatuses.add(item.status);
                        supplierGroups[groupID].add(item.status);
                    });
                    const newGroupStatus = determineMasterPurchaseOrderStatus(groupStatuses);
                    if (newGroupStatus !== group.calculated_purchase_order_status) {
                        group.calculated_purchase_order_status = newGroupStatus;
                    }
                });

                const groupStatuses = new Set();
                let groupStatusesChanged = false;
                data.item.purchase_orders_lines.forEach((group) => {
                    if (supplierGroups[group.id]) {
                        const newStatus = determineMasterPurchaseOrderStatus(supplierGroups[group.id]);
                        // console.log({
                        //     newStatus,
                        //     oldStatus: group.status,
                        //     supplier: group.supplier?.name,
                        //     supplierID: group.supplier?.id,
                        //     supplierGroupsBySupplierID,
                        //     group: group.id,
                        //     supplierGroups,
                        //     unmatchedLines,
                        // });
                        if (newStatus !== group.status) {
                            group.status = newStatus;
                            if (!groupStatusesChanged) {
                                groupStatusesChanged = true;
                            }
                        }
                    }
                    groupStatuses.add(group.status);
                    delete group.items;
                });

                if (unmatchedLines.length) {
                    groupStatuses.add(purchaseOrderStates.CONCEPT);
                    groupStatusesChanged = true;
                }

                if (groupStatusesChanged) {
                    // calculate master status
                    data.item.status = determineMasterPurchaseOrderStatus(groupStatuses);
                }

                return data;
            },

            async onSavePurchaseOrderPage(data) {
                delete data.item.drawing_board_lines;

                // Check for ordered lines without readonly = true
                data.item.purchase_orders_lines.forEach((group) => {
                    group.items.forEach((item) => {
                        if (item.status >= purchaseOrderStates.ORDERED && !item.readonly) {
                            item.readonly = 1;
                        }
                    });
                });

                return data;
            },

            onSaveInvoiceVariants,

            po_markAllAs(data) {
                const newStatus = data.status;
                let changed = false;
                data.data.group.items.forEach((item) => {
                    if (item.status < newStatus) {
                        item.status = newStatus;
                        changed = true;
                    }
                });

                if (changed) {
                    onPurchaseOrderLineStatusUpdate(data.data.mainItem);
                }
            },
        },
    },
    Fields: {
        methods: {
            calculateMargin({ value, data }) {
                if (value.startsWith('€')) {
                    const desiredTotal = value.replace('€', '').trim().replace(',', '.');
                    const marginPercentage = ((desiredTotal / data.item.price) - 1) * 100;
                    data.item.margin = marginPercentage.toFixed(3);
                }
            },

            onPurchaseOrderLineStatusUpdate({ data }) {
                onPurchaseOrderLineStatusUpdate(data.mainItem);
            },
        },
    },
};

export default custom_client;
