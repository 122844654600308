import getNamespace from '@/2/services/namespace';

const namespace = getNamespace();

export const build_routes = (pages) => {
    const routesList = [];

    const createChildrenItems = (route) => {
        const routeData = {
            pane: true,
            name: route.meta.name || '',
            editpane: route.meta.items.editpane?.api ? route.meta.items.editpane : { ...route.meta.items.editpane, api: route.meta.items.api },
            templates: route.meta.items?.templates || false,
        };
        return [
            {
                name: `${route.name}-add`,
                path: 'item/add',
                component: () => import('@/views/Editpane.vue'),
                meta: routeData,
                props(r) {
                    const props = { ...r.params };
                    props.id = 'add';
                    return props;
                },
            },
            {
                name: `${route.name}-item`,
                path: 'item/:id',
                component: () => import('@/views/Editpane.vue'),
                meta: routeData,
                props(r) {
                    const props = { ...r.params };
                    if (!Number.isNaN(+props.id)) {
                        props.id = +props.id;
                    }
                    return props;
                },
            },
        ];
    };

    const createChildrenItemsSidebar = (route) => {
        const routes = [];
        route.meta.items.forEach((dataset) => {
            const routeData = {
                pane: true,
                name: dataset.name || '',
                editpane: dataset.editpane?.api ? dataset.editpane : { ...dataset.editpane, api: dataset.api },
                dataset: dataset.api.table,
                templates: false,
            };

            routes.push(...[
                {
                    name: `${route.name}_${dataset.api.table}-add`,
                    path: `${dataset.api.table}/add`,
                    component: () => import('@/views/Editpane.vue'),
                    meta: routeData,
                    props(r) {
                        const props = { ...r.params };
                        props.id = 'add';
                        return props;
                    },
                },
                {
                    name: `${route.name}_${dataset.api.table}-item`,
                    path: `${dataset.api.table}/:id`,
                    dataset: (dataset.name || dataset.api.table),
                    component: () => import('@/views/Editpane.vue'),
                    meta: routeData,
                    props(r) {
                        const props = { ...r.params };
                        if (!['add', 'copy', 'variant'].includes(props.id)) {
                            props.id = +props.id;
                        }
                        return props;
                    },
                },
            ]);
        });
        return routes;
    };

    const createChildrenScanner = (route) => {
        const response = [];
        if (route.meta.scanner?.pages) {
            Object.entries(route.meta.scanner.pages).forEach(([name, page]) => {
                response.push({
                    name: `${route.name}-${name}`,
                    path: page.path,
                    component: () => import(/* webpackChunkName: "scanner" */ '@/components/scanner/pages/' + page.component + '.vue'), // eslint-disable-line
                    meta: {
                        guest: false,
                        nav: false,
                        path: name,
                        componentName: page.component,
                        baseUrl: route.path,
                        listeners: route.meta.scanner.listeners || {},
                        states: route.meta.scanner.states || {},
                        ...(page.meta || {}),
                    },
                    props(r) {
                        const props = { ...r.params };
                        if (props.id) {
                            props.id = +props.id;
                        }
                        return props;
                    },
                });
            });
        } else {
            console.error('scanner route does not contain pages');
        }
        return response;
    };

    const addPage = (page, index, parentSlug) => {
        // TODO check what 'in_routes' does
        if (!page.href && !page.path?.startsWith('http') && page.in_routes !== false) {
            const route = {
                path: page.path ? page.path : `/${parentSlug ? `${parentSlug}/` : ''}${page.slug}`,
                alias: index === 0 && !parentSlug ? '/' : undefined,
                pathname: `${parentSlug ? `${parentSlug}-` : ''}${page.slug}`,
                name: `${parentSlug ? `${parentSlug}-` : ''}${page.slug}`,
                component: () => import('@/views/' + page.component + '.vue'), // eslint-disable-line
                meta: page,
            };

            if (page.component === 'Items') {
                route.children = createChildrenItems(route);
            } else if (page.component === 'ItemsSidebar') {
                route.children = createChildrenItemsSidebar(route);
            } else if (page.component === 'Scanner') {
                route.children = createChildrenScanner(route);
                route.name = undefined;
            }

            routesList.push(route);
            if (Array.isArray(page.pages)) {
                page.pages.forEach((subpage, subindex) => addPage(subpage, subindex, page.slug));
            }
        }

        return true;
    };

    function addRoute(page, index) {
        if (page.path) {
            const route = {
                name: page.path,
                path: page.path,
                alias: index === 0 ? '/' : undefined,
                meta: page,
                component: () => import('@/views/' + page.component + '.vue'), // eslint-disable-line
            };

            switch (page.component) {
                case 'Items':
                    route.children = createChildrenItems(route);
                    break;
                case 'ItemsSidebar':
                    route.children = createChildrenItemsSidebar(route);
                    break;
                case 'Scanner':
                    route.children = createChildrenScanner(route);
                    route.name = undefined;
                    break;
                default:
                    break;
            }
            routesList.push(route);
        }

        if (Array.isArray(page.pages)) {
            page.pages.forEach((subpage, subindex) => addRoute(subpage, subindex));
        }

        return true;
    }

    if (['polarcompany.blixem.app', 'dfpaintball.blixem.app', 'jettiepallettie.blixem.app', 'abc-entertainment.blixem.app', 'ankekranendonk.blixem.app', 'ijssalondeverleiding.blixem.app', 'geenstempel.blixem.app', 'mindfuck.blixem.app', 'dennismusic.blixem.app'].includes(namespace)) {
        pages.forEach((page, index) => addRoute(page, index));
    } else {
        pages.forEach((page, index) => addPage(page, index));
    }
    return routesList;
};

export default build_routes;
