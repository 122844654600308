import io from 'socket.io-client';

const DEBUG = false;
const socketUrl = DEBUG ? 'http://localhost:3001' : 'https://ws.blixem.app';
const socket = io(socketUrl, {
    autoConnect: false,
    reconnectionAttempts: 4,
    reconnectionDelay: 60000,
});

export function join_room(
    name: string,
    data: Record<string, any>,
    onUpdateUsers: (users: Record<string, any>[], name: string) => void,
    onMessage: (msg: { type: string; data: Record<string, any> }) => void,
) {
    socket.emit('join_room', { room: name, ...data });
    socket.on(`room_users_changed:${name}`, (clients) => {
        if (typeof onUpdateUsers === 'function') {
            onUpdateUsers(clients, name);
        }
    });
    socket.on(`notify:${name}`, (msg) => {
        if (typeof onMessage === 'function') { onMessage(msg); }
    });
}

export function leave_room(name: string) {
    socket.emit('leave_room', { room: name });
    socket.off(`room_users_changed:${name}`);
    socket.off(`notify:${name}`);
}

export function notify_room(name: string, data: Record<string, any>) {
    socket.emit('notify_room', name, data);
}

export default socket;
