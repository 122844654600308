import debounce from 'lodash.debounce';
import { onSaveInvoiceVariants } from '@/mixins/invoices.paymentParts';

const custom_client = {
    Editpane: {
        methods: {
            onSaveInvoiceVariants,
        }
    },
    Fields: {
        methods: {
            onAmountUpdate: debounce(function debounced(val) {
                if (!this.data.item.amount_purchase && val.value) {
                    this.data.item.amount_purchase = val.value;
                }
            }, 300),
        },
    },
};

export default custom_client;
