/* eslint-disable no-use-before-define */
import { del } from 'vue';
import type { Modal, ModalProps } from './types';

/**
 * Returns full component path
 * @param path Full path to component inside /src.
 */
function getComponentPath(path: string): string {
    if (!path) { throw new Error('No modal path provided'); }

    return path;
}

export function createModal<T>({
    id, path, mask, closeOnNavigation, closeOnMaskClick, rejectOnClose, position, props,
}: ModalProps, modals: Record<string, Modal>) {
    const modal: Modal = {
        id,
        path: getComponentPath(path),
        opened: Date.now(),
        previousFocus: document?.activeElement,
        tabindex: 0,
        position: position || 'center',
        mask: typeof mask === 'boolean' ? mask : true,
        closeOnNavigation: typeof closeOnNavigation === 'boolean' ? closeOnNavigation : true,
        closeOnMaskClick: typeof closeOnMaskClick === 'boolean' ? closeOnMaskClick : true,
        rejectOnClose: typeof rejectOnClose === 'boolean' ? rejectOnClose : false,
        close: (type, data) => closeModal(modals, { id, type, data }),
        props: props || {},
        resolve: () => { },
        reject: () => { },
    };

    const modalPromise = new Promise<T>((resolve, reject) => {
        modal.resolve = resolve;
        modal.reject = reject;
    });

    return {
        modal,
        promise: modalPromise,
    };
}

export function focusModal(id: string) {
    setTimeout(() => {
        const el = document.querySelector(`.modals #${id} .modal`);
        if (el) { (el as HTMLElement).focus(); }
    }, 200);
}

export function closeModal(
    modals: Record<string, Modal>,
    { id, type = 'resolve', data = {} }: { id: string; type: 'resolve' | 'reject'; data?: Record<string, any> },
): void {
    const modal = modals[id];
    if (modal) {
        if (typeof modal[type] === 'function') { modal[type]!(data); }

        if (modal.previousFocus) { (modal.previousFocus as HTMLElement).focus(); }
        // NOTE vue2 needs 'del' to make it reactive
        del(modals, id);
        // delete modals[id];
    }
}

export function closeModalsOnNavigation(modals: Record<string, Modal>) {
    if (modals) {
        Object.values(modals).forEach((value) => {
            if (value.closeOnNavigation) { value.close?.('reject'); }
        });
    }
}

export function getLastOpenedModalID(modals: Record<string, Modal>): string {
    const lastModal = Object.values(modals).reduce<Modal | null>(
        (prev, cur) => (prev && prev.opened! > cur.opened! ? prev : cur),
        null,
    );
    if (lastModal) { return lastModal.id; }

    return '';
}
