import {
    quotesGetDays, getStaggeredDiscount, quotesStaggeredRecalc, quotesStaggeredCalcOnProductChange,
} from '@/mixins/staggeredDiscounts';
import onRepairComplete from '@/mixins/repairs/repairMixin';

import { setChoiceItems, editChoiceItems } from '@/components/checklist/mixins/choiceitems';
import { setChecklistItems } from '@/components/checklist/mixins/data';

const periodChangeCache = {};

const custom_client = {
    default: {
        methods: {
            editChoiceItems(item, settings) {
                return this.maloneyEditChoiceItems(item, { ...settings });
                // the custom function is neccessary to bind the 'this.$set' function in choiceitems.
            },
            maloneyEditChoiceItems: editChoiceItems,
        },
    },

    Periods: {
        mounted() {
            this.$on('action', this.onUpdatePeriodDates);
        },

        beforeDestroy() {
            this.$off('action', this.onUpdatePeriodDates);
        },

        methods: {
            onUpdatePeriodDates({ method = '', payload = {} } = {}) {
                if (method === 'onUpdateData' && payload.store && payload.key === 'periodDates') {
                    let largest;
                    let smallest;
                    Object.values(payload.data).forEach((dates) => {
                        const splitDates = dates.split('-');
                        splitDates.forEach((date) => {
                            if (+date > largest || !largest) {
                                largest = +date;
                            }
                            if (+date < smallest || !smallest) {
                                smallest = +date;
                            }
                        });
                    });
                    this.item.pickup = smallest;
                    this.item.return = largest;
                }
            },
        },
    },

    Rules: {
        mounted() {
            this.$on('changed', this.onRuleChanged);
            this.$on('action', this.onUpdatePeriodDates);
        },

        beforeDestroy() {
            this.$off('changed', this.onRuleChanged);
            this.$off('action', this.onUpdatePeriodDates);
        },

        methods: {
            onRuleChanged(payload = {}) {
                // TODO rewrite to hook
                if (this.config.api.table === 'bookings') {
                    if (payload.action === 'add-line' && !payload.item.period) {
                        if (this.item.bookings_periods.length) {
                            const lastPeriod = this.item.bookings_periods[
                                this.item.bookings_periods.length - 1
                            ];
                            this.$set(payload.item, 'period', lastPeriod.id);
                            this.$set(payload.item, 'days', lastPeriod.invoice_days);
                            this.$set(payload.item, 'volume_discount', this.getStaggeredDiscount(lastPeriod.invoice_days));
                        }
                    }

                    if (payload.action === 'change-line' && payload.type === 'period' && payload.val && payload.oldVal) {
                        const lowest = Math.min(payload.val, payload.oldVal);
                        const highest = Math.max(payload.val, payload.oldVal);
                        const key = `${payload.itemid}-${lowest}-${highest}`;
                        const timeLimit = 2; // seconds
                        const now = new Date().getTime();
                        if (!periodChangeCache[key] || periodChangeCache[key] < now - (timeLimit * 1000)) {
                            const oldPeriod = this.item.bookings_periods.find((it) => it.id === payload.oldVal);
                            if (oldPeriod.scan_state === 0) { // period not scanned. allow
                                return;
                            }

                            const totalOut = (payload.item.scanState?.outNr || 0) + (payload.item.scanState?.outNrTree || 0);
                            if (totalOut === 0) { // item not scanned. Allow
                                return;
                            }

                            const newPeriod = this.item.bookings_periods.find((it) => it.id === payload.val);
                            if (newPeriod.return < oldPeriod.return) { // new period is shorter. allow
                                return;
                            }

                            this.$toast('Dit item mag alleen naar een kortere periode verplaatst worden.', { classes: 'bg-danger' });
                            periodChangeCache[key] = now;
                            this.$set(payload.item, 'period', payload.oldVal);
                        }
                    }
                }
            },

            getStaggeredDiscount,

            setChecklistItems,

            onUpdatePeriodDates({ method = '', payload = {} } = {}) {
                if (method === 'onUpdateData' && payload.store && payload.key === 'periodDates') {
                    let largest;
                    let smallest;
                    Object.values(payload.data).forEach((dates) => {
                        const splitDates = dates.split('-');
                        splitDates.forEach((date) => {
                            if (+date > largest || !largest) {
                                largest = +date;
                            }
                            if (+date < smallest || !smallest) {
                                smallest = +date;
                            }
                        });
                    });
                    this.item.pickup = smallest;
                    this.item.return = largest;
                }
            },
        },
    },

    Fields: {
        methods: {
            onUpdateBookingsLinesDays({ value, data, trigger }) {
                this.$set(data.item, 'days', value.value || 0);
                this.quotesStaggeredRecalc({
                    value,
                    data,
                    field: { id: 'days' },
                    trigger,
                });
            },

            quotesGetDays,
            getStaggeredDiscount,
            quotesStaggeredRecalc,
            quotesStaggeredCalcOnProductChange,
            setChecklistItems,
            setChoiceItems,
        },
    },

    Editpane: {
        methods: {
            onRepairComplete,

            showWarningForMissingPeriods(data) {
                return new Promise((resolve, reject) => {
                    if ((data.item.bookings_lines[0]?.items || []).some((it) => it.itemid && !it.period && !it.is_purchaseitem)) {
                        this.$modal({
                            id: 'confirm',
                            path: 'Confirm',
                            title: this.$t('periods.lines_without'),
                            data: {
                                text: this.$t('periods.lines_without_text'),
                                buttonText1: this.$t('actions.edit'),
                                buttonText2: this.$t('actions.save'),
                                buttonClass1: 'bg-success',
                                button2Resolve: true,
                                buttonShortcuts1: ['n', 'esc'],
                                buttonShortcuts2: ['y', 'enter'],
                            },
                        })
                            .then(() => {
                                resolve(data);
                            })
                            .catch(reject);
                    } else {
                        resolve(data);
                    }
                });
            }
        },
    },
};

export default custom_client;
