import { generateID } from '@/services/helpers-ts';
import { postEvent } from '@/services/events';
import { api } from '@/assets/js/api';

async function postComment(type, comment, data, approved, practice, user) {
    const eventData = {
        type: 'custom',
        subtype: 'comment_created',
        trigger_type: 'blixem',
        name: `${type === 'step' ? 'Stap' : 'Bijlage'} ${approved ? 'goedgekeurd' : 'afgekeurd'}`,
        data: {
            message: comment,
            approved: !!approved,
        },
        notification: false,
        linked_table: type === 'step' ? 'steps' : 'files_2',
        linked_item: type === 'step' ? data.linked_to : data.id,
        user_id: user.id,
        user_name: user.name,
        user_avatar: user.avatar || '',
        user_table: 'users',
        practice: [practice.id],
    };
    return postEvent(eventData);
}

async function postInspiration(data, practice) {
    const inspirationData = {
        description: data.description,
        type: 'practice',
        step: data.linked_to,
        practice_group: practice.practice_group,
    };

    return api.post({
        name: `insert_inspiration_${Math.ceil(Math.random() * 1000)}`,
        endpoint: 'items/inspiration',
        data: inspirationData,
        loading: true,
    });
}

function getPracticeFromUser(user) {
    const practiceID = user.filter.item.id;
    const practice = user.data.practice.find((it) => it.id === practiceID);
    return practice;
}

export async function Approve(approve, { type, data }) {
    const user = this.$store.getters['Auth/user'];
    const practice = getPracticeFromUser(user);
    const title = approve ? this.$t('actions.approve') : this.$t('actions.disapprove');
    this.ApproveComment(title, !approve, approve && type === 'step' ? practice : undefined)
        .then(async (r) => {
            const newComment = {
                id: generateID(),
                remarks: r.form && r.form.message ? r.form.message : '',
            };
            const newComments = JSON.parse(JSON.stringify(data.comments));
            newComments.push(newComment);
            await postComment(type, r.form?.message || '', data, approve, practice, user);
            if (approve && type === 'step' && r.form?.inspiration) {
                await postInspiration(data, practice);
            }

            const updatedData = {
                notified: false,
            };
            if (type === 'step') {
                updatedData.has_unapproved_changes = approve ? 0 : 2;
            } else if (type === 'file') {
                updatedData.approved = approve ? 1 : 2;
                if (approve) {
                    updatedData.last_approved = Math.floor(Date.now() / 1000);
                }
            }
            if (type === 'step') {
                this.AfterStepApprove(data, updatedData);
            } else if (type === 'file') {
                this.AfterFileApprove(updatedData);
            }
        })
        .catch(() => { });
}

export function AfterFileApprove(updatedData) {
    Object.keys(updatedData).forEach((key) => {
        this.$set(this.item, key, updatedData[key]);
    });
    this.$set(this.item, 'file_approved', this.item.file);
    this.changed = true;
    this.onSave();
}

export function AfterStepApprove(data, updatedData) {
    const newValue = JSON.parse(JSON.stringify(this.value));
    const stepIndex = newValue.findIndex((it) => it.id === data.id);
    this.$delete(newValue, stepIndex);
    data = { ...data, ...updatedData };
    newValue.unshift(data);

    this.$emit('input', newValue);
    this.$nextTick(this.init);
}

export function ApproveComment(title, messageRequired = false, practice) {
    const inspirationCheckbox = [{
        id: 'inspiration',
        name: 'Deze aanvulling opnemen in de inspiratielijst',
        type: 'checkbox',
    }];

    return this.$modal({
        id: 'form',
        path: 'Form',
        title,
        data: {
            form: {
                message: '',
            },
            fields: [
                {
                    id: 'message',
                    name: this.$t('documents.send.fields.message'),
                    type: 'textarea',
                    attr: {
                        required: messageRequired,
                    },
                },
                ...(practice?.show_in_inspiration ? inspirationCheckbox : []),
            ],
        },
    });
}
