export function recursiveChildFunction<T extends Record<string, any>>(items: T[], childKey: string, fn: (_it: T, _currentPath: string) => void, path?: string) {
    if (Array.isArray(items)) {
        items.forEach((item, index) => {
            const childPath = path ? `${path}.${index}` : `${index}`;
            fn(item, childPath);
            if (item[childKey]) {
                recursiveChildFunction(item[childKey], childKey, fn, childPath);
            }
        });
    } else {
        return false;
    }
    return true;
}

export function pathParentPath(path: string) { return path.substring(0, path.lastIndexOf('.')); }

export function generateID(min = 1000000000, max = 9999999999) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

// TODO move to services
export function flattenObjectParameters(obj: Record<string, any>, prefix = '') {
    if (!obj) { return obj; }
    return Object.keys(obj).reduce<Record<string, any>>((params, key) => {
        const pre = prefix.length ? `${prefix}.` : '';
        if (typeof obj[key] === 'object') {
            Object.assign(params, flattenObjectParameters(obj[key], pre + key));
        } else {
            params[pre + key] = obj[key];
        }
        return params;
    }, {});
}

type SortDirection = 'asc' | 'desc';
type SortSyntax = `${string}:${SortDirection}`; // column:dir
interface SortSyntaxObject { column: string; direction: SortDirection }

export function sortSyntaxToObject(string: SortSyntax): SortSyntaxObject {
    const [column, direction] = string.split(':');

    if (!direction || (direction !== 'asc' && direction !== 'desc')) {
        console.debug('Invalid sort direction, defaulting to asc');

        return { column, direction: 'asc' };
    }

    return { column, direction };
}

export function sortObjectToSyntax({ column, direction }: SortSyntaxObject): SortSyntax {
    if (!direction || (direction !== 'asc' && direction !== 'desc')) {
        console.debug('Invalid sort direction, defaulting to asc');
        direction = 'asc';
    }

    return `${column || ''}:${direction}`;
}
