import get from 'lodash.get';
import debounce from 'lodash.debounce';
import Vue from 'vue';
import EventBus from '@/assets/js/event-bus';
import { generateID } from '@/services/helpers-ts';

const custom_client = {
    default: {
        mounted() {
            if (this.$vnode && this.$vnode.tag.includes('GoogleSlidesModal')) {
                this.$set(this, 'googleSlidesReplaceValues', [
                    { replace: 'name' },
                    {
                        replace: 'client',
                        with: 'data.item.client.name',
                    },
                    { replace: 'client.email' },
                    { replace: 'client.phone' },
                    {
                        replace: 'company',
                        with: 'data.item.company.name',
                    },
                    { replace: 'currency' },
                    {
                        replace: 'date',
                        template: "this.timestamp_to(arguments[0], 'date-short')",
                    },
                    { replace: 'location' },
                    { replace: 'excludes' },
                    { replace: 'payment_info' },
                    { replace: 'planning' },
                    { replace: 'summary' },
                    {
                        replace: 'number',
                        template: 'arguments[0].toString()',
                    },
                    {
                        replace: 'post_production_days',
                        template:
                            "Array.isArray(arguments[0]) ? arguments[0].map(it => this.timestamp_to(it, 'dd mm yyy')).join(', ') : ''",
                    },
                    {
                        replace: 'pre_production_days',
                        template:
                            "Array.isArray(arguments[0]) ? arguments[0].map(it => this.timestamp_to(it, 'dd mm yyy')).join(', ') : ''",
                    },
                    {
                        replace: 'production_days',
                        template:
                            "Array.isArray(arguments[0]) ? arguments[0].map(it => this.timestamp_to(it, 'dd mm yyy')).join(', ') : ''",
                    },
                    { replace: 'location' },
                ]);
            }
        },
    },

    Editpane: {
        methods: {
            // hook on quotes-editpane-save
            disableSendAllowed(data) {
                if (data.item.send_allowed && !this.paneConfig.rights.approve) {
                    data.item.send_allowed = false;
                }
                return Promise.resolve(data);
            },

            // Travel & Expenses
            onBeforeOpen() {
                if (get(this.configuration, 'api.table') === 'quotes') {
                    EventBus.$on('travel-totals-calculated', this.calculateTravelPerGroup);
                }
            },

            onBeforeClose() {
                if (get(this.configuration, 'api.table') === 'quotes') {
                    EventBus.$off('travel-totals-calculated');
                }
            },

            calculateTravelPerGroup: debounce(function debounced() {
                const totals = get(this.$refs, 'segment_travel.calculations.groups', false);
                const changed = () => {
                    this.changed = true;
                };

                // calculate travel lines
                function calculateTravel(groups = [], path = '', combined = 0) {
                    let combinedGroupAmount = combined;
                    groups.forEach((group, index) => {
                        const groupPath = path ? `${path}.${index}` : index.toString();
                        let subGroupTotalAmount = 0;
                        if (group.groups && group.groups.length) {
                            subGroupTotalAmount = calculateTravel(group.groups, groupPath, 0);
                        }
                        let groupTotals = get(totals, `[${group.id}].travel_totals.exc_vat`, 0);
                        if (!path) {
                            combinedGroupAmount = 0;
                            groupTotals -= subGroupTotalAmount;
                        }

                        const groupname = 'Travel & Expenses';
                        const existing = group.items.findIndex((it) => it.name === groupname);

                        if (existing > -1) {
                            if (groupTotals > 0) {
                                if (
                                    group.items[existing].price !== groupTotals
                                    && group.items[existing].price_internal !== groupTotals
                                ) {
                                    group.items[existing].price = groupTotals;
                                    group.items[existing].price_internal = groupTotals;
                                    changed();
                                }
                            } else {
                                Vue.delete(group.items, existing);
                                changed();
                            }
                        } else if (groupTotals > 0) {
                            const line = {
                                travel: false,
                                amount: 1,
                                readonly: true,
                                price: groupTotals,
                                price_internal: groupTotals,
                                name: groupname,
                                id: generateID(),
                            };

                            group.items.push(line);
                        }

                        combinedGroupAmount += groupTotals;
                    });
                    return combinedGroupAmount;
                }

                if (totals) {
                    calculateTravel(this.item.products);
                } else {
                    console.error('no totals');
                }
            }, 500),
        },
    },

    RulesGroup: {
        methods: {
            onCalculateSupport() {
                const group = get(this.settings.calculations.groups, this.group.id, false);
                if (
                    group
                    && group.products_totals
                    && typeof group.products_totals.exc_vat === 'number'
                ) {
                    const groupTotal = group.products_totals.exc_vat;
                    let projectTotal = this.item.products_totals.exc_vat;
                    projectTotal -= groupTotal;

                    this.group.items.forEach((item) => {
                        const percentage = parseFloat(item.name.match(/ - ([\d.]+)%$/)[1], 10) || 0;
                        this.$set(item, 'pre', 1);
                        this.$set(
                            item,
                            'price',
                            percentage > 0 ? (projectTotal / 100) * percentage : 0,
                        );
                    });
                }
            },
        },
    },
};

export default custom_client;
