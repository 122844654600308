const predefinedVibrationPatterns = {
    attention: [300],
    success: [75, 75, 150],
    error: [400, 100, 400, 100, 400],
};

export default function vibrate(pattern: keyof typeof predefinedVibrationPatterns | number[]) {
    if (!window.navigator.vibrate || !pattern) return;

    try {
        navigator.vibrate(0); // Stop any current vibration
    } catch (e) {
        //
    }

    if (typeof pattern === 'string') {
        if (!(pattern in predefinedVibrationPatterns)) return;

        pattern = predefinedVibrationPatterns[pattern];
    }

    try {
        navigator.vibrate(pattern);
    } catch (e) {
        //
    }
}
