<template>
    <bottom class="nav__bottom" v-if="user && user.name">
        <router-link class="navigation__icon flex justify-center" :to="profileLink">
            <Avatar :path="user.data?.avatar" :value="user.name" />
        </router-link>

        <router-link class="flex-grow text-ellipsis leading-xs" :to="profileLink">
            <div class="text-ellipsis">
                {{ user.name }}
            </div>
            <div class="size-7 color-secondary">
                Blixem {{ $store.state.app.version }}
            </div>
        </router-link>

        <Dropdown handle-class="-ghost" direction="top" align="right">
            <button :title="$t('actions.change_theme')" @click.stop="$theme.toggle">
                {{ $t('fields.theme') }}: {{ $t('fields.' + theme) }}
                <icon class="mdi" :class="{
                    'mdi-weather-night': theme === 'dark',
                    'mdi-weather-sunny': theme === 'light',
                    'mdi-brightness-auto': theme === 'auto'
                }" />
            </button>
            <router-link to="/logout">
                {{ $t('login.actions.logout') }}
                <icon class="mdi mdi-lock-open-outline" />
            </router-link>
        </Dropdown>
    </bottom>
</template>

<script>
import Dropdown from '@/components/ui/Dropdown.vue';

export default {
    name: 'User',
    components: { Dropdown },
    computed: {
        theme() {
            return this.$theme.current;
        },

        user() {
            return this.$store.getters['Auth/user'];
        },

        profileLink() {
            return { path: `/settings/users/item/${this.user.id}`, query: { returnUrl: this.$route.path } };
        },
    },
};
</script>

<style scoped>
.nav__bottom {
    display: grid;
    grid-template-columns: var(--navigation-link-line-height) 1fr 4rem;
    grid-template-rows: auto;
    grid-gap: var(--size);
    align-items: center;
}

.nav__bottom .navigation__label {
    overflow: hidden;
}
</style>
