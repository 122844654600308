import kebabcase from 'lodash.kebabcase';

const custom_client = {
    Editpane: {
        methods: {
            async setNewsSlug(data) {
                data.item.slug = kebabcase(data.item.slug || data.item.name);
                return data;
            },
        },
    },
};

export default custom_client;
