export const lightOrDark = (color) => {
    // color = rgb or hex.
    // returns if color is light or dark.
    let r;
    let g;
    let b;
    if (color.match(/^rgb/)) {
        [color, r, g, b] = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    } else {
        color = +(`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`);
        r = color >> 16;
        // eslint-disable-next-line
        g = color >> 8 & 255;
        b = color & 255;
    }
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
    if (hsp > 145) { // was 127.5
        return 'light';
    }
    return 'dark';
};

export default lightOrDark;
