const getSavedTheme = (current) => {
    try {
        const savedTheme = localStorage.getItem('theme-color');
        return savedTheme && savedTheme !== current ? savedTheme : false;
    } catch (err) {
        return false;
    }
};

const storeTheme = (theme) => {
    try {
        return localStorage.setItem('theme-color', theme);
    } catch (err) {
        return false;
    }
};

const setColorScheme = (data) => {
    if (!window.matchMedia) {
        return;
    }
    const darkMode = window.matchMedia('(prefers-color-scheme: dark)');
    const lightMode = window.matchMedia('(prefers-color-scheme: light)');
    const noPrefMode = window.matchMedia('(prefers-color-scheme: no-preference)');

    if (darkMode.matches) data.set('dark', 'preferred');
    if (lightMode.matches) data.set('light', 'preferred');
    if (noPrefMode.matches) {
        const now = new Date();
        const hour = now.getHours();
        if (hour < 4 || hour >= 16) {
            data.set('dark', 'preferred');
        }
    }

    try {
        // Chrome & Firefox
        darkMode.addEventListener('change', (e) => e.matches && data.set('dark', 'preferred'));
        lightMode.addEventListener('change', (e) => e.matches && data.set('light', 'preferred'));
    } catch (e1) {
        try {
            // Safari
            darkMode.addListener((e) => e.matches && data.set('dark', 'preferred'));
            lightMode.addListener((e) => e.matches && data.set('light', 'preferred'));
        } catch (e2) {
            console.error(e2);
        }
    }
};

const optionsDefaults = {
    themes: ['light', 'dark'],
    current: 'light',
    preferred: '',
};

export default {
    install(Vue, opts = {}) {
        const options = { ...optionsDefaults, ...opts };
        Vue.config.productionTip = false;

        const root = new Vue({
            data: options,
            methods: {
                set(value, type = 'current') {
                    if (this.themes.includes(value) || value === 'auto') {
                        this[type] = value;
                        if (type === 'current') {
                            storeTheme(value);
                        }
                    }
                    document.documentElement.classList.remove(
                        ...this.themes.map((it) => `theme-${it}`),
                    );
                    document.documentElement.classList.add(
                        `theme-${this.get()}`,
                    );
                },
                get() {
                    return this.current === 'auto'
                        ? this.preferred
                        : this.current;
                },
                getThemes() {
                    return this.preferred
                        ? [...this.themes, 'auto']
                        : this.themes;
                },
                toggle() {
                    console.log(this);
                    const themes = this.getThemes();
                    const currentIndex = themes.indexOf(this.current);
                    const nextIndex = (currentIndex + 1) % themes.length;
                    this.set(themes[nextIndex]);
                },
            },
        });
        Vue.prototype.$theme = root;

        // Restore saved theme
        const savedTheme = getSavedTheme(options.current);
        if (savedTheme) {
            root.set(savedTheme);
        }

        // Set auto-theme
        setColorScheme(root);
    },
};
