<template>
    <div id="app" class="m-down:layout__bottom-nav l-up:layout__sidebar-nav bg-bg color-primary">
        <template v-if="pages.length && navigationVisible">
            <NavigationBar v-if="$route.meta?.body_class?.includes('blixem2')" :pages="$store.state.config?.current?.pages">
                <template #header>
                    <Avatar
                        class="grow-0 shrink-0"
                        v-bind="{
                            size: 10,
                            sizeMdMax: 6,
                            modelValue: item.user_avatar,
                            text: item.user_name,
                            icon: item.user_name ? undefined : avatarIcons[item.trigger_type || 'unknown'] || avatarIcons.unknown,
                        }"
                    />
                </template>
            </NavigationBar>

            <transition v-else name="fade">
                <Navigation
                    v-if="pages.length && navigationVisible" hide-on-scroll="main"
                    :layout="size === 'desktop' ? 'sidebar' : 'bottom'" :pages="pages" :more-text="$t('actions.more')"
                    :logo="{ title: $store.getters.companyName, subtitle: user.subtitle, titleClass: user.multipleAccounts ? 'cursor-pointer hover:bg-hover' : '' }"
                    @on-logo-text-click="user.multipleAccounts ? $store.dispatch('switchAccounts') : {}"
                >
                    <template v-if="user.multipleAccounts" #logo-after-text>
                        <icon class="mdi mdi-chevron-down color-tertiary" />
                    </template>
                    <router-link slot="logo" to="/" class="user__avatar navigation__logo">
                        <Avatar type="logo" />
                    </router-link>

                    <template #content>
                        <NavBottom />
                    </template>
                </Navigation>
            </transition>
        </template>

        <main id="main">
            <router-view view-method="route" :route="$route" />

            <a v-if="navigationVisible" href="#sidenav" aria-label="Open menu" class="button icon-only sidenav__open background outline md:hidden" @click.stop>
                <span class="mdi mdi-menu icon" />
            </a>
        </main>

        <Loading :show="$store.state.app.loading_fullscreen.show" :message="$store.state.app.loading_fullscreen">
            <svg id="lightning" class="animated" viewBox="0 0 179 360.44">
                <path
                    id="lightning__bolt--bottom"
                    d="M0 138.89v54.41a19.38 19.38 0 0019.3 19.3H58v138.2c0 9.9 13 13.3 18 4.8l42.9-73.6 57.4-98.5a19.08 19.08 0 00-16.6-28.8H116l5.91-15.81z"
                />
                <path
                    id="lightning__bolt--top"
                    d="M115.81 154.63l4.91-12.55L138.2 95.3l25.9-69.2A19.15 19.15 0 00146.2 0H19.3A19.38 19.38 0 000 19.3v174a19.38 19.38 0 0019.3 19.3h38.55c4.61-11.06 12-26.53 19.83-34.48 13.07-13.2 38.13-23.49 38.13-23.49z"
                />
                <path
                    id="lightning__shadow"
                    d="M138.2 95.3l25.9-69.2A19.15 19.15 0 00146.2 0H55.7c44.3 12 75.9 54.7 82.5 95.3zm21.5 59.4h-35.3c-12.9 16.6-29.7 28.9-31.6 53.7-2.4 31.2 21.2 46.2 26.1 73.8l57.4-98.5a19.2 19.2 0 00-16.6-29z"
                />
            </svg>
        </Loading>

        <Toasts />
        <Modals />
        <modals-container :loader="loadmodal" :route-path="$route.path" />

        <ImgCrop v-if="$store.state.imageCrop.enabled" />
    </div>
</template>

<script>
// NOTE; when refactoring this to script setup
// Make sure that route-watchers are updating, for the showing / hiding of the nav.
import Loading from '@/assets/js/Loading.vue';
import NavBottom from '@/components/NavigationBottom.vue';
import serviceWorker from '@/mixins/serviceWorker';
import getNamespace from '@/2/services/namespace';

export default {
    name: 'App',
    components: {
        Loading,
        Navigation: () => import(/* webpackChunkName: "navigation" */ '@/components/ui/Navigation/index.vue'),
        NavigationBar: () => import(/* webpackChunkName: "navigation" */ '@/2/components/navigation/index.vue'),
        NavBottom,
        ImgCrop: () => import('@/components/ImageCrop.vue'),
    },
    mixins: [serviceWorker],

    computed: {
        navigationVisible() {
            return this.$store.getters['Auth/isAuthenticated']
                && this.$route.meta.nav !== false
                && (!this.$route.meta.pane || this.size === 'desktop');
        },

        size() {
            return this.$store.getters.size;
        },

        pages() {
            const invisiblePages = this.invisiblePagesBecauseOfRights;
            const namespace = getNamespace();

            const pagereducer2 = (acc, cur) => {
                if (cur.pages) {
                    cur.pages = cur.pages.reduce(pagereducer2, []);
                }
                if (
                    cur.in_menu
                    && (!invisiblePages.includes(cur.path) || (cur.pages && cur.pages.some((it) => !invisiblePages.includes(it.path))))
                    && (cur.path || cur.href || (Array.isArray(cur.pages) && cur.pages.length))
                ) {
                    acc.push(cur);
                }

                return acc;
            };

            const pagereducer = (acc, cur) => {
                if (cur.pages) {
                    const curCopy = JSON.parse(JSON.stringify(cur));
                    curCopy.pages = curCopy.pages.reduce(pagereducer, []);
                    cur = curCopy;
                }
                let path = cur.path || cur.pathname || '';
                if (!path.startsWith('/')) {
                    path = `/${path}`;
                }
                if (
                    cur.in_menu
                    && (!invisiblePages.includes(path) || (cur.pages && cur.pages.some((it) => !invisiblePages.includes(it.path))))
                    && (cur.path || cur.to || cur.href || (Array.isArray(cur.pages) && cur.pages.length))
                ) {
                    acc.push(cur);
                }

                return acc;
            };

            if (['abc-entertainment.blixem.app', 'ankekranendonk.blixem.app', 'polarcompany.blixem.app', 'ijssalondeverleiding.blixem.app', 'geenstempel.blixem.app', 'mindfuck.blixem.app', 'dennismusic.blixem.app'].includes(namespace)) {
                const pagesCopy = JSON.parse(JSON.stringify(this.$store.state.config?.current?.pages || []));
                return pagesCopy.reduce(pagereducer2, []);
            }
            return (this.$store.state.config?.current?.pages || []).reduce(pagereducer, []);
        },

        invisiblePagesBecauseOfRights() {
            const rights = this.$store.getters['Rights/rights'];
            return Object.keys(rights).filter(it => rights[it].view === false);
        },

        user() {
            const user = this.$store.getters['Auth/user'];
            const accounts = this.$store.getters['Auth/accounts'];
            const multipleAccounts = accounts.length > 1 ? true : accounts.some(it => it.length > 1);

            return {
                user,
                multipleAccounts,
                subtitle: user?.filter?.item?.name || '',
            };
        },
    },

    created() {
        this.initResize();
    },

    methods: {
        initResize() {
            if (!window.matchMedia) {
                return;
            }
            const minWidth = 480;
            const isTablet = window.matchMedia(`(min-width: ${minWidth}px) and (max-width: 992px)`);
            const handleSizeChange = (e) => {
                if (e.matches) {
                    this.$store.commit('set_size', 'tablet');
                } else if (window.innerWidth < minWidth) {
                    this.$store.commit('set_size', 'phone');
                } else {
                    this.$store.commit('set_size', 'desktop');
                }
            };
            isTablet.addListener(handleSizeChange);
            handleSizeChange(isTablet);
        },

        loadmodal(path) {
            const component = () => import(`./2/components/modals/${path}.vue`);
            console.log({ path, component });
            return component;
            // return defineAsyncComponent(() => import(`./2/components/modals/${path}.vue`));
        },
    },
};
</script>
