import { api } from '@/assets/js/api';

function SmartLockSupported() {
    return !!(
        (window.PasswordCredential || window.FederatedCredential)
        && window.PublicKeyCredential
    );
}

export default {
    namespaced: true,
    state: {
        token: '',
        tokens: [],
        user: {},
        accounts: [],
    },
    getters: {
        isAuthenticated: (state) => !!(state.user && state.token),
        user: (state) => state.user,
        accounts: (state, getters) => (getters.isAuthenticated ? state.accounts : []),
        token: (state) => state.token,
        rights: (state, getters) => (mod, permission) => {
            if (!getters.isAuthenticated || !state.user.rights_processed) { return false; }
            try {
                return state.user.rights_processed[mod] && state.user.rights_processed[mod].enabled && (!permission || state.user.rights_processed[mod].permissions[permission]);
            } catch (err) {
                return false;
            }
        },
    },
    mutations: {
        login(state, { user, token = '', users } = {}) {
            if (user && user.name) {
                user.firstname = user.name.split(' ')[0];
            }
            state.user = user || {};
            if (token || !user) {
                state.token = token;
            }

            if (users || !user) {
                state.accounts = users;
                const tokens = (users || []).reduce((acc, cur) => {
                    acc.push(...cur.map((it) => it.token));
                    return acc;
                }, []);
                state.tokens = tokens;
                if (tokens && tokens.length) {
                    const data = { tokens, token };
                    localStorage.setItem('authentication_v2', JSON.stringify(data));
                } else {
                    localStorage.removeItem('authentication_v2');
                }
            }
        },

        setRights(state, rights_processed = {}) {
            state.user.rights_processed = rights_processed;
        },
    },
    actions: {
        login({ dispatch }, data) { // data = email, password, token
            return new Promise((resolve, reject) => {
                api.post({
                    name: 'login',
                    endpoint: 'login/verify',
                    data,
                })
                    .then((r) => dispatch('loginSuccess', { r, trigger: 'verify' }))
                    .then(resolve)
                    .catch((err) => {
                        console.error(err);
                        reject(err);
                    });
            });
        },

        loginWithProvider(context, { url, returnPath = window.location.href }) {
            const returnUrl = encodeURIComponent(returnPath);
            const to = `${url}${returnUrl}`;
            console.debug('LoginWithProvider:', to);
            window.location.href = to;
        },

        loginTwoFactor({ dispatch }, data) { // data = email, password, code, user
            return new Promise((resolve, reject) => {
                api.post({
                    name: 'login',
                    endpoint: 'login/twofactor',
                    data,
                })
                    .then((r) => dispatch('loginSuccess', { r, trigger: 'twofactor' }))
                    .then(resolve)
                    .catch((err) => {
                        console.error(err);
                        reject(err);
                    });
            });
        },

        loginSuccess({ dispatch }, { r, trigger }) {
            return new Promise((resolve, reject) => {
                if (r.data.status !== 'success') {
                    reject(new Error('Error in login response', r));
                } else if (trigger !== 'twofactor' && r.data.users && r.data.users.length && r.data.users.some((it) => it.user?.twofactor)) {
                    resolve({ twofactor: true, user: r.data.users[0].user.id });
                } else if (r.data.users && r.data.users.length) {
                    dispatch('setSession', r.data).then((res) => resolve(res));
                } else {
                    reject(new Error('No users in response', r));
                }
            });
        },

        passReset(context, { email }) {
            return new Promise((resolve, reject) => {
                api.post({
                    name: 'passReset',
                    endpoint: 'login/forgotpassword',
                    data: { email },
                }).then((r) => {
                    if (!r.data || r.data.status !== 'success') {
                        throw new Error('Response error');
                    }
                    resolve({ status: 'success' });
                }).catch((err) => {
                    try {
                        const data = JSON.parse(err.data);
                        if (data.code && data.code === 44) {
                            reject(new Error('unsupported'));
                        } else {
                            console.error(err);
                            reject(new Error('other error'));
                        }
                    } catch (er) {
                        console.error(err);
                        reject(new Error('unknown_account'));
                    }
                });
            });
        },

        logout({ commit, dispatch, state }) {
            dispatch('deleteSession', { tokens: state.tokens });
            dispatch('SmartLockLogout');
            commit('login');
        },

        setSession({ commit }, data) {
            data.users.forEach((user, index) => {
                if (user.sessions.length) {
                    user.sessions.forEach((session) => {
                        Object.assign(session.user, user.user);
                    });
                } else {
                    delete data.users[index];
                }
            });

            const users = data.users.filter((it) => it).map((it) => it.sessions);
            if (users.length === 1 && users[0].length === 1) {
                commit('login', { user: users[0][0].user, token: users[0][0].token, users });
                return new Promise((resolve) => { resolve({ user: users[0], data, users }); });
            }

            // When multiuser, check if restoreSessionFilter matches a session to restore
            const urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
            if (Object.keys(urlParams).includes('restoreSessionFilter')) {
                for (let i = 0; i < users.length; i += 1) {
                    for (let j = 0; j < users[i].length; j += 1) {
                        if (
                            users[i][j].user
                            && users[i][j].user.filter
                            && users[i][j].user.filter.filter
                            && users[i][j].user.filter.filter === urlParams.restoreSessionFilter
                        ) {
                            commit('login', { user: users[i][j].user, token: users[i][j].token, users });
                            return new Promise((resolve) => { resolve({ user: [users[i][j]], data, users }); });
                        }
                    }
                }
            }

            // When multiuser, check if storedtoken matches a session to restore.
            const storedData = JSON.parse(localStorage.getItem('authentication_v2'));
            const storedToken = (storedData && storedData.token) ? storedData.token : false;
            if (storedToken) {
                for (let i = 0; i < users.length; i += 1) {
                    for (let j = 0; j < users[i].length; j += 1) {
                        if (users[i][j].token === storedToken) {
                            commit('login', { user: users[i][j].user, token: users[i][j].token, users });
                            return new Promise((resolve) => {
                                resolve({
                                    user: [users[i][j]], data, users,
                                });
                            });
                        }
                    }
                }
            }
            return new Promise((resolve) => { resolve({ data, users }); });
        },

        restoreSession({ dispatch }, data = JSON.parse(localStorage.getItem('authentication_v2'))) {
            return new Promise((resolve, reject) => {
                if (data && data.tokens) {
                    api.post({
                        name: 'login',
                        endpoint: 'login/retrieve',
                        data: { tokens: data.tokens },
                        loading: true,
                    })
                        .then((r) => dispatch('loginSuccess', { r, trigger: 'verify' }))
                        .then(resolve)
                        .catch((err) => {
                            dispatch('logout');
                            console.error(err);
                            reject(err);
                        });
                } else {
                    reject(new Error('no token'));
                }
            });
        },

        deleteSession(context, { tokens }) {
            api.delete({
                name: 'delete_session',
                endpoint: 'login/',
                data: { tokens },
            }).then((r) => {
                const url = r.data.logout_url;
                if (url) {
                    window.location.href = url;
                }
            });
        },

        SmartLockGet() {
            if (SmartLockSupported()) {
                return navigator.credentials.get({ password: true });
            }
            return Promise.reject(new Error('unsupported'));
        },

        SmartLockSave(context, { id, password }) {
            if (id && password && SmartLockSupported()) {
                // eslint-disable-next-line
                const cred = new PasswordCredential({ id, password });
                navigator.credentials.store(cred)
                    .catch((err) => { console.debug('error while storing the credential', err); });
            }
        },

        SmartLockLogout() {
            // Google Smart Lock - Prevent automatic re-sign-in
            if (SmartLockSupported()) {
                navigator.credentials.preventSilentAccess();
            }
        },
    },
};
