/* eslint-disable no-console */
import { register } from 'register-service-worker';

if (process.env.NODE_ENV !== 'development') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.debug('App is being served from cache by a service worker.\n'
        + 'For more details, visit https://goo.gl/AFskqB');
        },
        cached() {
            console.debug('Content has been cached for offline use.');
        },
        updated(registration) {
            console.debug('New content is available; please refresh.');
            document.dispatchEvent(
                new CustomEvent('swUpdated', { detail: registration }),
            );
        },
        offline() {
            console.debug('No internet connection found. App is running in offline mode.');
        },
        error(error) {
            console.error('Error during service worker registration:', error);
        },
    });
}
