import onRepairComplete from '@/mixins/repairs/repairMixin';
import { setChecklistItems } from '@/components/checklist/mixins/data';

const custom_client = {
    Editpane: {
        methods: {
            onRepairComplete,
        },
    },

    Fields: {
        methods: {
            setChecklistItems,
        },
    },
};

export default custom_client;
