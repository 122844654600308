const custom_client = {
    custom_favicons: true,

    default: {},

    login: {
        methods: {
            onBeforeSetUser(user) {
                if (
                    user.filter?.item?.id !== 1
                    && [1].includes(user.role) // 1 = admin
                ) {
                    user.role = 3; // 3 = admin-editor
                }
                return user;
            },
        },
    },

    Items: {
        methods: {
            onRouteChange(val) {
                if (val === '/sites') {
                    const user = this.$store.getters['Auth/user'];
                    if (
                        ![1].includes(user.role) // 1 = admin
                        && user.filter?.item?.id
                    ) {
                        if (user.filter.item.id !== 1) {
                            this.$router.push({
                                name: 'sites-item',
                                params: { id: user.filter.item.id },
                            });
                        }
                    }
                }
            },
        },
        watch: {
            '$route.path': {
                handler: 'onRouteChange',
                immediate: true,
            },
        },
    },
};

export default custom_client;
