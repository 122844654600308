<template functional>
    <tag
        v-if="data.staticClass || (typeof props.value !== 'undefined' && props.valueClasses[props.value.toString()])"
        class="-s"
        :class="data.staticClass || props.valueClasses[props.value.toString()]"
    />
</template>

<script>
export default {
    name: 'Dot',
    props: {
        value: [Number, String],
        valueClasses: Object,
    },
};
</script>
