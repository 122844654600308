import difference from 'lodash.difference';
import {
    getChecklist,
    convertProductToChecklist,
    convertChecklist,
} from '@/components/checklist/mixins/data';

/**
 * get and set choiceitems when an item is added to a booking.
 * @returns updated data in promise
 * @param {object} data - Object containing `item` (changed values for the single line), and `context`, containing `hookContext` from fields.vue
 * used as a fields-hook
 */
export async function setChoiceItems(data) {
    data.item.choiceitems = [];
    data.item.choiceprice = 0;
    if (
        data.context.option?.choiceitems
        && (typeof data.context.option.choiceitems === 'boolean'
            || (Array.isArray(data.context.option.choiceitems)
                && (data.context.option.choiceitems[0]?.items || []).length))
    ) {
        data.item.has_choiceitems = true;
        const choiceitems = await this.editChoiceItems(data.item, { mode: 'select' });
        if (choiceitems && Array.isArray(choiceitems.items)) {
            data.item.choiceitems = choiceitems.items;
            data.item.choiceprice = choiceitems.price;
        }
        return data;
    }
    // has no choiceitems
    data.item.has_choiceitems = false;
    return data;
}

export async function editChoiceItems(item, settings) {
    // settings.mode = select|edit
    // settings.maxPerGroup = number
    try {
        const response = await this.$modal({
            id: 'choiceitems',
            path: 'ChoiceItems',
            title: this.$tc('items.items_choice', 2),
            data: {
                item,
                settings,
            },
        });
        if (response && Array.isArray(response.items)) {
            if (settings.mode === 'select') {
                return response;
            }
            this.$set(item, 'choiceitems', response.items);
            this.$set(item, 'choiceprice', response.price);
            this.$emit('changed');
            return response;
        }
        return false;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

const mixin = {
    mounted() {
        // Check if a checklistkey and amountkey are set, and watch choiceitem changes
        const checklistSettings = this.settings.options.checklists
            ? this.settings.options.checklists
            : {};
        if (checklistSettings.key && checklistSettings.choiceitems) {
            this.$watch('item.choiceitems', this.onChoiceItemsUpdated, { deep: true });
        }
    },
    methods: {
        async onChoiceItemsUpdated(val, oldVal) {
            // Choiceitem-ids
            const oldIDs = oldVal && Array.isArray(oldVal) ? oldVal.map((it) => it.id) : [];
            const newIDs = val && Array.isArray(val) ? val.map((it) => it.id) : [];

            // Delete old checklist items
            const deleteIDs = difference(oldIDs, newIDs);
            this.deleteOldChoiceItemChecklistValues(deleteIDs);

            // Fetch checklists for new items
            const addIDs = difference(newIDs, oldIDs); // added ids
            if (addIDs.length) {
                const newChoiceItems = val.filter((it) => addIDs.includes(it.id));
                const newProductIDs = val.map((it) => (addIDs.includes(it.id) ? it.itemid : undefined));
                const uniqueNewProductIDs = [...new Set(newProductIDs)];

                const checklists = await getChecklist({
                    ids: uniqueNewProductIDs,
                    table: this.settings.segment.options.checklists.table,
                    as_checklistitem: true,
                }).catch(() => {
                    this.$toast({
                        msg: this.$t('checkin.errors.fetching_checklist'),
                        classes: 'bg-danger',
                    });
                });
                if (checklists) {
                    const returnedChecklists = this.processChoiceItemChecklists(
                        uniqueNewProductIDs.length === 1 ? [checklists] : checklists,
                        uniqueNewProductIDs,
                        newChoiceItems,
                    );
                    if (returnedChecklists && returnedChecklists.length) {
                        this.item[this.settings.segment.options.checklists.key][0].items.push(
                            ...returnedChecklists,
                        );
                    }
                }
            }
        },

        deleteOldChoiceItemChecklistValues(ids = []) {
            if (ids.length) {
                const list = this.item[this.settings.segment.options.checklists.key];
                if (list && list[0] && list[0].items) {
                    list[0].items = list[0].items.filter((it) => !ids.includes(it.choiceitemid));
                }
            }
        },

        processChoiceItemChecklists(checklists, productIDs, newChoiceItems) {
            return newChoiceItems.reduce((acc, choiceitem) => {
                const productOrder = productIDs.indexOf(choiceitem.itemid);

                // Checklistitem for main product
                const checklist = convertProductToChecklist(
                    choiceitem,
                    choiceitem.id,
                    this.item.amount,
                );

                // Child-checklists
                if (checklists[productOrder]) {
                    const listdata = JSON.parse(JSON.stringify(checklists[productOrder]));
                    const checklistItems = convertChecklist(
                        listdata,
                        choiceitem.id,
                        this.item.amount * (choiceitem.amount || 1),
                    );
                    if (checklistItems) {
                        checklist.items = checklistItems;
                    }
                }
                acc.push(checklist);
                return acc;
            }, []);
        },
    },
};

export default mixin;
