export default {
    namespaced: true,
    state: {
        types: {},
    },
    mutations: {
        clear(state) {
            state.types = {};
        },

        setTypes(state, data) {
            state.types = data;
        },
    },
    actions: {
        set({ commit }, { config, key }) {
            const types = {};
            Object.entries(config).forEach(([configKey, value]) => {
                if (configKey !== 'current') {
                    types[configKey] = value[key].types.reduce((acc, cur) => {
                        acc[cur.type] = cur;
                        return acc;
                    }, {});
                }
            });

            commit('setTypes', types);
        },
    },
    getters: {
        config: (state) => (lang, type) => state.types[lang][type] || false,
    },
};
