import { onSaveInvoiceVariants } from '@/mixins/invoices.paymentParts';

const custom_client = {
    Editpane: {
        methods: {
            onSaveInvoiceVariants,
        }
    },
};

export default custom_client;
