<template>
    <div class="avatar color-accent w-32 h-32 relative rounded overflow-hidden" :class="{ 'avatar-nobg': type === 'logo' }"
        :title="value">
        <img v-if="value && value.startsWith('http')" :src="value" class="-stretch" />
        <img v-else-if="path" :src="path" class="-stretch" />
        <img v-else-if="type === 'logo'" :src="'/img/icons/' + namespace + '/favicon.svg'" class="-stretch"
            @error="imgFailed = true" alt="Logo" />
        <icon v-else-if="type === 'user'" class="mdi mdi-account avatar__user"></icon>
        <icon v-else-if="value" class="mdi" :class="['mdi-alpha-' + value.trim().toLowerCase().substring(0, 1)]"></icon>
    </div>
</template>

<script>
export default {
    name: 'Avatar',
    props: {
        value: String,
        type: String,
        path: String,
    },
    data() {
        return {
            imgFailed: false,
        };
    },
    computed: {
        namespace() {
            if (this.imgFailed) {
                return 'blixem';
            }
            return this.$store.getters.namespace;
        },
    },
};
</script>

<style>
.avatar:not(.avatar-nobg) {
    background-color: var(--color-accent-3);
}

.avatar icon {
    --font-size: var(--size-2);
    margin: calc(var(--font-size) * -0.7) 0 0 calc(var(--font-size) * -0.7);
    position: absolute;
    top: 50%;
    left: 50%;
}

.avatar icon.avatar__user {
    margin: calc(var(--font-size) * -0.6) 0 0 calc(var(--font-size) * -0.7);
}</style>
