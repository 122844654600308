import { api } from '@/assets/js/api';

export async function postEvent(input = {}) {
    // TODO test allowed data-params
    if (!input.name || !typeof input.name === 'string') {
        throw new Error('invalid-name');
    }

    if (!input.type || !typeof input.type === 'string') {
        throw new Error('invalid-type');
    }

    if (!input.endpoint) {
        input.endpoint = window.location.href;
    }

    const response = await api.post({
        name: `insert_event_${Math.ceil(Math.random() * 1000)}`,
        endpoint: 'items/events_2',
        data: input,
        loading: true,
    }).catch((err) => {
        throw new Error(err);
    });
    if (response.data && response.data.status === 'success') {
        return response.data.data;
    }
    throw new Error('invalid response');
}

export async function patchEvent(input = {}) {
    if (!input?.id) {
        throw new Error('invalid-id');
    } else {
        const { id, ...data } = input;
        return api.patch({
            name: `update_event_${input.id}`,
            endpoint: `items/events_2/${input.id}`,
            data,
            loading: true,
        });
    }
}
export default postEvent;
