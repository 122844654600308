<template>
    <Dropdown v-if="is_selectable && !readonly && Object.keys(list).length" v-bind="{ search }" @search="searchVal = $event"
        parentSelector="pane" handleClass="flex align-center">
        <template v-slot:trigger="{ state, changeState }">
            <slot name="trigger" v-bind="{ changeState, selectedValues }">
                <div class="tags inline-flex tags--linked" @click="changeState('toggle')">
                    <tag v-if="selectedValues.length === 0" class="-s -rounded bg-border cursor-pointer hover:bg-hover">
                        {{ name || $t('fields.status') }}
                        <icon v-if="!readonly && selectedValues.length < 2" class="mdi tag__suffix -ghost"
                            :class="{ 'mdi-chevron-down': state === 'closed', 'mdi-chevron-up': state === 'opened' }" />
                    </tag>
                    <tag v-for="val in selectedValues" :key="val.item.id"
                        class="-s -rounded bg-border cursor-pointer hover:bg-hover" :class="val.class" :style="val.style">
                        {{ val.item[displayKey] || name || $t('fields.status') }}
                        <icon v-if="!readonly && selectedValues.length < 2" class="mdi tag__suffix -ghost"
                            :class="{ 'mdi-chevron-down': state === 'closed', 'mdi-chevron-up': state === 'opened' }" />
                    </tag>
                </div>
            </slot>
            <icon v-if="!readonly && (selectedValues.length >= 2 || showToggle)" class="mdi tag__suffix -ghost"
                :class="{ 'mdi-chevron-down': state === 'closed', 'mdi-chevron-up': state === 'opened' }"
                @click="changeState('toggle')" />
        </template>
        <button type="button" v-if="allowClear && selectedValues.length" @click="select()">
            {{ $t('actions.delete') }}
            <icon class="mdi mdi-close" />
        </button>
        <button type="button" v-for="id in filteredKeys" :key="'opt_' + id" v-show="id !== value && list[id].allow_manual_change !== 0"
            @click="select(id, $event)" :class="{
                'bg-accent-lightest': selectedIDs.includes((outputFormat ? list[id][outputFormat === 'object' ? 'id' : outputFormat] : id).toString()),
            }">
            {{ list[id][displayKey] }}
            <tag v-if="list[id].color" class="ml-r flex-shrink-0" :style="{ backgroundColor: list[id].color }" />
        </button>
        <button type="button" v-if="!filteredKeys.length" disabled>
            {{ $t('fields.choices_no-results') }}
        </button>
    </Dropdown>
    <div v-else-if="selectedValues.length">
        <slot name="trigger" v-bind="{ selectedValues }">
            <div class="tags inline-flex tags--linked">
                <tag v-for="val in selectedValues" :key="val.item.id" :class="[val.class, '-s -rounded']"
                    :style="val.style">
                    <template v-if="!dot">{{ val.item[displayKey] || '' }}</template>
                </tag>
            </div>
        </slot>
    </div>
</template>

<script>
import { lightOrDark } from '@/assets/helpers/color';

export default {
    name: 'StatusTag',
    components: {
        Dropdown: () => import('@/components/ui/Dropdown.vue'),
    },
    props: {
        selectable: Boolean,
        showToggle: Boolean,
        multiple: Boolean,
        dot: Boolean,
        allowClear: Boolean,
        group: {
            type: String,
            default: 'status',
        },
        options: Array,
        value: [Number, String, Array],
        outputFormat: String,
        readonly: Boolean,
        search: Boolean,
        in_user_lang: Boolean,
        name: String,
    },
    data() {
        return {
            searchVal: '',
            displayKey: 'name',
        };
    },
    mounted() {
        if (this.in_user_lang) {
            this.displayKey = `name_${this.$store.getters['Auth/user'].lang}`;
        }
    },
    computed: {
        is_selectable() {
            return this.selectable && !this.readonly && !this.selectedValues.some((it) => it.item?.allow_manual_change === 0);
        },

        list() {
            if (this.options?.length) {
                return this.options.reduce((acc, it) => {
                    acc[it.id] = it;
                    return acc;
                }, {});
            }
            return this.$store.getters.cats({ group: this.group, defaultOutput: {} });
        },

        sortedKeys() {
            return Object.keys(this.list).sort((a, b) => (this.list[a].sort < this.list[b].sort ? -1 : 1));
        },

        filteredKeys() {
            if (this.searchVal) {
                const val = typeof this.searchVal === 'string' ? this.searchVal.toLowerCase() : this.searchVal.toString();
                return Object.entries(this.list).reduce((acc, [key, value]) => {
                    if (typeof value.name === 'string') {
                        if (value.name.toLowerCase().includes(val)) {
                            acc.push(key);
                        }
                    } else if (value.name.toString().includes(val)) {
                        acc.push(key);
                    }
                    return acc;
                }, []);
            }
            return this.sortedKeys;
        },

        selectedValues() {
            if (!this.value) { return []; }
            if (Array.isArray(this.value)) {
                return this.value.map(this.getSingleDisplayValue);
            }
            return [this.getSingleDisplayValue(this.value)];
        },

        selectedIDs() {
            return this.selectedValues.map((it) => (it.item?.id || '').toString());
        },
    },

    methods: {
        // id: number or undefined
        select(id, $event) {
            if ($event && this.multiple) {
                $event.stopPropagation();
            }
            if (!id) {
                this.$emit('input', this.multiple ? [] : undefined);
                return;
            }

            let newValue = +id;
            if (this.outputFormat) {
                if (this.outputFormat === 'object') {
                    newValue = this.list[id];
                } else {
                    newValue = this.list[id][this.outputFormat];
                }
            }

            if (!this.multiple) {
                this.$emit('input', newValue);
                return;
            }

            if (this.selectedIDs.includes(id.toString())) {
                this.$emit('input', this.value.filter((it) => it !== +id && it?.id !== +id));
            } else {
                this.$emit('input', [...this.value, newValue]);
            }
        },

        getSingleDisplayValue(it) {
            const response = {
                item: {},
                style: {},
                class: '',
            };

            const match = it && typeof it === 'object' ? it.id : it;

            if (!this.outputFormat) {
                response.item = this.list[match] || {};
            } else {
                const outputKey = this.outputFormat === 'object' ? 'id' : this.outputFormat;
                const list = Array.isArray(this.list) ? this.list : Object.values(this.list);
                response.item = list.find((item) => item[outputKey] === match) || {};
            }

            if (response.item.color) {
                response.style = { 'background-color': response.item.color };
                if (lightOrDark(response.item.color) === 'dark') {
                    response.class = 'color-gray-200';
                } else {
                    response.class = 'color-gray-800';
                }
            }

            return response;
        },
    },
};
</script>
