<template>
    <div class="modals" tabindex="-1">
        <ComponentLoader
            v-for="id in Object.keys(modals)"
            :key="'modal_' + id"
            :ref="'modal_' + id"
            v-bind="modals[id]"
            @close="update({ open: false, id, data: $event })"
        />
    </div>
</template>

<script>
import events from './events';

export default {
    name: 'Modals',
    data() {
        return {
            modals: {},
        };
    },
    mounted() {
        events.$on('addModal', this.update);
        events.$on('closeLastModal', this.closeLastModal);
    },
    methods: {
        update({
            open = true, id, data = {}, force = false, ...values
        }) {
            if (open) {
                if (!values.path.endsWith('.vue')) { values.path = `segments/modals/${values.path}.vue`; }
                values.type = values.path;
                delete values.path;
                this.$set(this.modals, id, {
                    ...values, id, opened: Date.now(), data,
                });
            } else {
                const modal = this.modals[id];
                if (modal) {
                    if (!force && typeof modal.resolve === 'function') {
                        modal.resolve(data);
                    } else if (force && typeof modal.reject === 'function') {
                        modal.reject();
                    }
                }
                this.$delete(this.modals, id);
            }
            events.$emit('modalCount', Object.keys(this.modals).length);
        },

        closeLastModal() {
            const lastModal = Object.values(this.modals).reduce((prev, cur) => (prev.opened > cur.opened ? prev : cur));
            if (lastModal && this.$refs[`modal_${lastModal.id}`]) {
                const modal = this.$refs[`modal_${lastModal.id}`][0].$children[0];
                if (modal && typeof modal.close === 'function') {
                    modal.close({ trigger: 'closeLast' });
                } else {
                    this.update({ open: false, id: lastModal.id, force: true });
                }
            }
        },
    },
};
</script>

<style>
    .modals {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
    }
</style>
