import {
    quotesGetDays, getStaggeredDiscount, quotesStaggeredRecalc, quotesStaggeredCalcOnProductChange,
} from '@/mixins/staggeredDiscounts';

import { setChecklistItems } from '@/components/checklist/mixins/data';

const custom_client = {
    Editpane: {
        methods: {
        },
    },

    Fields: {
        methods: {
            quotesGetDays,
            getStaggeredDiscount,
            quotesStaggeredRecalc,
            quotesStaggeredCalcOnProductChange,
            setChecklistItems,
        },
    },
};

export default custom_client;
