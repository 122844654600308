import { api } from '@/assets/js/api';

export async function onSaveInvoiceVariants(data) {
    // this function saves changes made to the variants rules-module
    if (!this.changed) {
        return data;
    }

    // check for changed 'send_date' values in cllctv
    const variantsWithChangedSendDate = data.item.variants?.filter((variant) => {
        return typeof variant.send_date !== 'undefined' && (variant.send_date !== variant.send_date_copy || !!variant.send_date_locked !== !!variant.send_date_locked_copy);
    }) || [];

    // check for changed 'status' values in cllctv
    const variantsWithChangedStatus = data.item.variants?.filter((variant) => typeof variant.status !== 'undefined' && variant.status !== variant.status_copy) || [];

    // check for modified payments in individual invoice-variants.
    const variantsWithPaymentsWithoutID = data.item.variants?.filter((variant) => Array.isArray(variant.payments) && variant.payments.some((p) => !p.id)) || [];

    if (!variantsWithChangedSendDate.length && !variantsWithPaymentsWithoutID.length && !variantsWithChangedStatus.length) {
        return data;
    }

    const newPayments = [];
    const modifiedInvoices = new Map();
    function addChangesToInvoice(id, changes) {
        if (!modifiedInvoices.has(id)) {
            modifiedInvoices.set(id, { id });
        }
        Object.assign(modifiedInvoices.get(id), changes);
    }

    variantsWithChangedSendDate.forEach((variant) => {
        addChangesToInvoice(variant.id, { send_date: variant.send_date, send_date_locked: variant.send_date_locked });
    });

    variantsWithChangedStatus.forEach((variant) => {
        if (!variant.number) {
            addChangesToInvoice(variant.id, { status: variant.status, number: '', sequence: '' , invoice_company: variant.invoice_company }); // invoice company is optional
            // prevent already saved error
            delete data.item.edited;
            delete data.item.number;
            delete data.item.sub_numbers;
        } else {
            addChangesToInvoice(variant.id, { status: variant.status });
        }
    });

    variantsWithPaymentsWithoutID.forEach((variant) => {
        variant.payments.forEach((payment) => {
            if (payment.id) {
                return;
            }
            newPayments.push({ ...payment, item: variant.id });
            addChangesToInvoice(variant.id, { paid: variant.paid });
        });
    });

    if (newPayments.length) {
        await api.post({
            name: 'post-paymentparts',
            endpoint: 'items/invoices_payments/',
            abort_prefer_running: true,
            data: newPayments,
        });
    }
    if (modifiedInvoices.size) {
        await api.patch({
            name: 'post-invoice-paid-status',
            endpoint: 'items/invoices/',
            abort_prefer_running: true,
            data: [...modifiedInvoices.values()],
        });
    }

    return data;
}