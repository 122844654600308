/* eslint-disable import/order */
import Vue from 'vue';

// Plugins
import { api } from '@/assets/js/api';
import Modals from '@/components/ui/Modals';
import Modals2 from '@/2/components/compat-utils/modals';
import Address from 'p5-address';
import VueBarcodeScanner from 'vue-barcode-scanner';
import VTooltip from 'v-tooltip';
import Multiselect from '@/components/ui/Multiselect/index';
import get from 'lodash.get';
import { flattenObjectParameters } from '@/services/helpers-ts';
import { init as errorHandlingInit, reportError } from '@/services/errorhandling/';
import Toaster from './components/plugins/Toaster';
import socket from '@/2/services/useSockets.instance';
import router from './router';
import store from './store';
import './registerServiceWorker';
import i18n from './2/services/language';
import App from './App.vue';
import theme from './assets/js/theme';

// Global Mixins
import dates from './mixins/dates';
import './mixins/filters';

// Global Components
import Dot from './components/Dot.vue';
import StatusTag from './components/Status.vue';
import Avatar from './components/Avatar.vue';
import State from './components/State.vue';
import ComponentLoader from './components/loader.vue';

import getNamespace from './2/services/namespace';
import createFavicons from './2/services/favicons';

// Blixem 1
import '@/assets/css/layout.scss';
import '@/assets/css/tables.scss';
import '@/assets/css/tags.scss';
import '@/assets/css/priceTotals.scss';
import '@/assets/css/segments.scss';
import '@/assets/css/checkin.scss';
import '@/assets/css/tooltip.scss';
import '@/assets/css/cardslayout.scss';
import '@/assets/css/scanner.scss';

// Both
import '@mdi/font/css/materialdesignicons.min.css';

// Blixem 1
import '@/assets/css/spark/style.scss';
import '@/assets/css/application.scss';
import '@/assets/css/forms.scss';

// Blixem2
import '@/2/assets/css/app.scss';

Vue.config.productionTip = false;
Vue.config.ignoredElements = ['bar', 'bottom', 'card', 'firstload', 'icon', 'loading', 'pane', 'page', 'search', 'tag', 'tools', 'top'];

global.router = router;
global.store = store;

const namespace = getNamespace();
createFavicons(namespace);
errorHandlingInit({ version: APPLICATION_VERSION, namespace });
if (namespace) {
    let client_functions;
    try {
        // eslint-disable-next-line
        client_functions = require('./mixins/clients/' + namespace);
        client_functions = client_functions.default;
        window.client_functions = client_functions;
    } catch (err) {
        console.debug('⚡ No custom client mixins found, nice');
        window.client_functions = {};
    }

    store.commit('setNamespace', namespace);

    if (client_functions && client_functions.default) {
        Vue.mixin(client_functions.default);
    }
}

// Global Mixins
Vue.mixin(dates);
Vue.mixin({
    methods: {
        insertValue(data, key, args) {
            if (!data[`${key}_template`]) { return data[key] || ''; }
            const context = this;
            if (data[`${key}_template`].includes('this.data.flattened_item')) {
                context.data.flattened_item = flattenObjectParameters(context.data?.item || {});
            }
            // eslint-disable-next-line
            const result = new Function(`return ${data[`${key}_template`]};`).bind(context, args)();
            if (result) { return result; }
            return data[key] || '';
        },

        checkIf(ifValue, args, context = this) {
            try {
                if (typeof ifValue === 'string') {
                    if (ifValue.includes('this.flattened_item')) {
                        context.flattened_item = flattenObjectParameters(context.item || {});
                    }
                    if (ifValue.includes('return ')) {
                        console.debug('ifvalue contains `return`');
                        // eslint-disable-next-line
                        return new Function(`"use strict"; ${ifValue}`).bind(context, args)();
                    }
                }
                // eslint-disable-next-line
                return new Function(`"use strict"; return ${ifValue};`).bind(context, args)();
            } catch (err) {
                reportError(err, {
                    extra: {
                        msg: 'invalid checkIf', template: ifValue,
                    },
                    fingerprint: 'invalid-checkif',
                    tags: { section: 'invalid-checkif' },
                });
                return '';
            }
        },

        processTemplateValues(obj, context) {
            const keys = Object.keys(obj);
            if (keys.find(it => it.endsWith('_template'))) {
                const newObj = JSON.parse(JSON.stringify(obj));
                Object.keys(newObj).forEach((key) => {
                    if (key.endsWith('_template')) {
                        if (typeof newObj[key] === 'string') {
                            newObj[key.slice(0, -9)] = this.checkIf(newObj[key], { newObj }, context);
                        } else if (typeof newObj[key] === 'object') {
                            if (Array.isArray(newObj[key])) {
                                newObj[key.slice(0, -9)] = newObj[key].reduce((acc, cur) => {
                                    const arrObj = this.processTemplateValues(cur, context);
                                    acc.push(arrObj);
                                    return acc;
                                }, []);
                            } else {
                                newObj[key.slice(0, -9)] = this.processTemplateValues(
                                    newObj[key],
                                    context,
                                );
                            }
                        }
                        delete newObj[key];
                    }
                });
                return newObj;
            }
            return obj;
        },

        getValue(column) {
            const valKey = column.value || column.id;
            const item = this.item ? this.item : this.data.item;

            // Value
            let val;
            if (valKey) {
                val = valKey.includes('.') ? get(item, valKey, undefined) : item[valKey];
            }
            if (column.value_template) {
                val = this.checkIf(column.value_template, { val });
            }

            if (val && column.categories) {
                if (Array.isArray(column.categories)) {
                    val = column.categories[val];
                } else {
                    val = this.$store.getters.cats({
                        group: column.categories,
                        defaultOutput: column.categories_key || 'name',
                        search: {
                            val,
                        },
                    });
                }
            }

            // Formatting & Filters
            if (column.value_filter) {
                let [filter, ...args] = column.value_filter;
                if (filter === 'price') {
                    filter = 'priceformat';
                } else if (filter === 'price-if-defined') {
                    if (val) {
                        filter = 'priceformat';
                    } else {
                        return '';
                    }
                }
                args = args.map((arg) => {
                    if (typeof arg === 'string' && arg.startsWith('## ')) {
                        arg = this.checkIf(arg.substr(3));
                    }
                    return arg;
                });
                try {
                    val = this.$options.filters[filter](val, ...args);
                } catch (err) {
                    console.error('filter not defined:', filter, column);
                }
            }
            if (column.categories && val === 0) {
                return '';
            }
            if (val || typeof val === 'number') {
                return val;
            }
            if (column.value_default || typeof column.value_default === 'number') {
                return column.value_default;
            }
            return '';
        },

        onlink(payload = {}) {
            const ctrl = window.event?.ctrlKey || window.event?.metaKey;

            // Build url
            let url = this.insertValue(payload, 'url', payload);
            if (payload.params) { // Add params to url
                url += url.includes('?') ? '&' : '?';
                url += Object.entries(payload.params).map(([key, val]) => {
                    if (key.endsWith('_template')) {
                        return `${key.slice(0, -9)}=${encodeURIComponent(this.checkIf(val))}`;
                    }
                    return `${key}=${encodeURIComponent(val)}`;
                }).join('&');
            }
            if (payload.pdf) {
                const filename = this.insertValue(payload, 'filename') || this.data.item.name || 'bestand';
                url = `${process.env.VUE_APP_PRINT_URL}?url=${encodeURIComponent(url)}&filename=${encodeURIComponent(filename.replace(/[^a-zA-Z0-9\s():\-_.]/g, ''))}`;
            }

            if (payload.blank || ctrl) {
                window.open(url);
            } else if (payload.router) {
                const obj = { path: url };
                if (!payload.url_template || !payload.url_template.includes('returnUrl')) {
                    obj.query = { returnUrl: router.currentRoute.path };
                }
                router.push(obj);
            } else if (payload.download) {
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.style = 'display: none';
                a.download = true;
                a.href = url;
                a.click();
            } else if (payload.background) {
                api.get({
                    name: 'background_request',
                    url,
                })
                    .then(() => { this.$toast(this.$i18n.t('toasts.command_sent')); })
                    .catch((err) => {
                        this.$toast(this.$i18n.t('toasts.command_failed'), { classes: 'bg-danger' });
                        console.error(err);
                    });
            } else {
                window.location.href = url;
            }
        },
    },
});

// Global Components
Vue.component('Dot', Dot);
Vue.component('Status', StatusTag);
Vue.component('State', State);
Vue.component('Avatar', Avatar);
Vue.component('ComponentLoader', ComponentLoader);
Vue.component('Multiselect', Multiselect);

// Plugins
Vue.use(Address, { key: process.env.VUE_APP_GOOGLE_API_KEY });
Vue.use(VueBarcodeScanner, { sound: false, sensitivity: 40 });
Vue.use(Toaster);
Vue.use(Modals);
Vue.use(Modals2);
Vue.use(theme);
Vue.use(VTooltip);

window.sockets = socket;

window.vue_app = new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');

if (!namespace && !window.location.pathname.startsWith('/web')) {
    // Website instead of app
    router.replace({
        path: '/web',
    });
}
