const custom_client = {
    Editpane: {
        watch: {
            // showTab: {
            //     handler(newVal, oldVal) {
            //         if (newVal && oldVal) {
            //             const segments = this.$el.querySelector('.segments');
            //             if (segments) {
            //                 segments.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            //             }
            //         }
            //     },
            // },
        },
    },
};

export default custom_client;
