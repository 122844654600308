import { api } from '@/assets/js/api';
import { reportError, reportMessage } from '@/services/errorhandling/';

export const get = ({
    endpoint, data, loading = true, provideRetryData = false, name,
}) => new Promise((resolve, reject) => {
    api.get({
        name: name || endpoint,
        endpoint,
        data,
        loading,
    }).then((r) => {
        if (r.data.results) {
            resolve(r.data.results);
        } else if (r.data) {
            resolve(r.data);
        } else {
            throw new Error('No data');
        }
    }).catch((err) => {
        if (provideRetryData) {
            err.retryData = {
                err, endpoint, data, loading,
            };
            reject(err);
        } else {
            reject(err);
        }
        store.dispatch('Errors/fire', { error: err, allowedCodes: [404] });
    });
});

export const remove = ({ endpoint, ids = [], loading = true }) => api.delete({
    name: `delete_${endpoint}`,
    endpoint: `${endpoint}/${ids.join(',')}/`,
    loading,
});

export const patch = ({ endpoint, data, loading = true }) => new Promise((resolve, reject) => {
    api.patch({
        name: `patch_${endpoint}`,
        endpoint,
        data,
        loading,
    }).then((r) => {
        resolve(r.data.data);
    }).catch((err) => {
        reject(err);
        if (err && err.status && [403, 409, 410].includes(err.status)) {
            // don't report
        } else if (err && ['Network error', 'Timeout'].includes(err.message)) {
            reportMessage(err, { tags: { section: 'services/items-patch' } });
        } else {
            reportError(err, { tags: { section: 'services/items-patch' } });
        }
    });
});

export const post = ({ endpoint, data, loading = true }) => new Promise((resolve, reject) => {
    api.post({
        name: `post_${endpoint}`,
        endpoint,
        data,
        loading,
    }).then((r) => {
        resolve(r.data.data);
    }).catch((err) => {
        reject(err);
        if (err && err.status && [403, 409, 410].includes(err.status)) {
            // don't report
        } else if (err && ['Network error', 'Timeout'].includes(err.message)) {
            reportMessage(err, { tags: { section: 'services/items-post' } });
        } else {
            reportError(err, { tags: { section: 'services/items-post' } });
        }
    });
});

export const unarchive = ({ table, ids = [] }) => new Promise((resolve, reject) => {
    api.post({
        name: `unarchive_item_${ids.join('-')}`,
        endpoint: `restore/${table}/${ids.join(',')}/`,
    })
        .then(resolve)
        .catch((err) => {
            reject(err);
            if (err && ['Network error', 'Timeout'].includes(err.message)) {
                reportMessage(err, { tags: { section: 'services/items-unarchive' } });
            } else {
                reportError(err, { tags: { section: 'services/items-unarchive' } });
            }
        });
});

export function mergePatchItemData(patchData, item) {
    Object.keys(patchData).forEach((key) => {
        if (
            key.includes('[push]')
            || key.includes('[unshift]')
        ) {
            const cleanKey = key.replace(/\[(push|unshift)\]/g, '');
            patchData[cleanKey] = item[cleanKey];
            patchData[cleanKey].push(patchData[key]);
            delete patchData[key];
        }
    });
    return patchData;
}

export default get;
