import { getRightsSegment } from '@/services/rights';
import { setCategoryMaps, buildCategoryMaps } from './categories';
import { processPages } from './pages';

export const processConfig = ({ config }) => {
    const langKeys = Object.keys(config);
    const firstConfigLang = config[langKeys[0]];

    if (firstConfigLang.categories) {
        const cats = buildCategoryMaps(firstConfigLang.categories);
        config = setCategoryMaps({ config, cats });
    }

    if (firstConfigLang.pages) {
        Object.entries(config).forEach(([lang, conf]) => {
            conf.pages = processPages(conf, lang);
        });
    }

    getRightsSegment(config);
    return config;
};

export default processConfig;
