export const restore = (key = 'localStore') => new Promise((resolve, reject) => {
    try {
        let data = localStorage.getItem(key);
        if (data) {
            data = JSON.parse(data);
            if (data && typeof data === 'object') {
                resolve(data);
            }
        }
        resolve({});
    } catch (err) {
        reject(err);
    }
});

export const store = (data, key = 'localStore') => {
    const storeData = JSON.stringify(data);
    localStorage.setItem(key, storeData);
};

export const update = ({
    data, page, component, key, value,
}, existingData = {}) => {
    const hasValue = !(!value || JSON.stringify(value) === '{}');
    if (data) {
        return data;
    }
    let path;
    if (page) {
        if (!existingData[page]) {
            if (!hasValue) { return existingData; }
            existingData[page] = {};
        }
        path = existingData[page];
    }
    if (component) {
        if (!existingData[page][component]) {
            existingData[page][component] = {};
        }
        path = path[component];
    }
    if (key) {
        if (path[key] && !hasValue) {
            delete path[key];
        } else if (hasValue) {
            path[key] = value;
        }
    }
    return existingData;
};

export const get = (input, { page, component }) => {
    let data = {};
    if (page && input && typeof input[page] === 'object') {
        data = input[page];
    }
    if (component) {
        if (typeof data[component] === 'object') {
            data = data[component];
        } else {
            data = {};
        }
    }
    return data;
};

export default restore;
